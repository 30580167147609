import React, { useState } from "react"

/*
  Utility function for DraggableBase.
  Anything that can be clicked must be wrapped and defined on this component.
*/

const DraggableTouch = ({ children, onClick, onTouchStart, onTouchEnd, maxDistance = 5, ...rest }) => {
  const [distance, setDistance] = useState(0) 
  const _onClick = onClick || onTouchStart || onTouchEnd

  return (
    <div
      onTouchStart={() => setDistance(0)}
      onTouchMove={() => setDistance(distance + 1)}
      onTouchEnd={() => distance <= maxDistance && _onClick()}
      onClick={_onClick}
      {...rest}
    >
      {children}
    </div>
  )
}

export default DraggableTouch 