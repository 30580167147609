import types from "../types/workflows"

export const workflows = (state = { incidents: [], assessments: [], trainings: [], tasks: [], fetched: false }, { type, payload }) => {
  switch (type) {

    case types.fetchWorkflows:
      const workflows = payload?.workflows?.filter(i => i);
      const incidents = workflows?.filter(workflow => workflow.type === "INCIDENT") ?? []
      const assessments = workflows?.filter(workflow => workflow.type === "ASSESSMENT") ?? []
      const trainings = workflows?.filter(workflow => workflow.type === "TRAINING") ?? []
      const tasks = workflows?.filter(workflow => workflow.type === "TASK") ?? []
      return {
        ...state,
        incidents,
        assessments,
        trainings,
        tasks,
        fetched: true,
      }
    case types.removeWorkflows:
      return {
        ...state,
        incidents: [],
        assessments: [],
        trainings: [],
        tasks: [],
        fetched: false,
      }
    default:
      return state
  }
}

export default workflows