import React from 'react'
import IncidentPieChart from './IncidentPieChart'
import cn from 'classnames'
import c from './HotelIncidentSummaryItem.module.scss'

const HotelIncidentSummaryItem = props => {
  const {
    imageURL,
    name,
    crisis,
    severe,
    major,
    minor,
    className,
    ...rest
  } = props

  const total = minor + major + severe + crisis

  return (
    <div className={cn(c.item, className)} {...rest}>
      <div className={c.imageContainer}>
        <IncidentPieChart 
          minor={minor} 
          major={major} 
          severe={severe} 
          crisis={crisis} 
        />
        <img src={imageURL} className={c.image} alt=''/>
      </div>
      <div className={c.content}>
        <div className={c.name}>{name}</div>
        <div className={c.total}>{total}</div>
        <div className={c.stats}>
          <div className={c.minor}>{minor}</div> /
          <div className={c.major}>{major}</div> /
          <div className={c.severe}>{severe}</div> /
          <div className={c.crisis}>{crisis}</div>
        </div>
      </div>
    </div>
  )
}

export default HotelIncidentSummaryItem
