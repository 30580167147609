import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import Button from "aius-comp/Button"
import FileInfo from "aius-comp/FileInfo"
import CloseHeader from "../components/CloseHeader"
import sanitizeHtml from "sanitize-html"
import c from "./AssessmentBegin.module.scss"
import Page from "../components/Page"
import Loading from "aius-comp/Loading"
import axios from "axios"

/*  
  Start assessment run.
  Displays description and documents of the assessment type.
*/

const AssessmentBegin = ({ props }) => {
  const history = useHistory()

  // Get workflow ID as URL parameter
  const { id } = useParams()

  // Get workflow from state by ID
  const [assessment, setAssessment] = useState()
  const [loading, setLoading] = useState(true)

  // Call fetch workflows action creator, if workflows haven't been fetched yet
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/workflows/${id}/full`)
        .then(res => setAssessment(res.data?.workflow))
        .catch(err => console.error(err)).finally(() => setLoading(false))
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <Loading size='large' />
      </div>
    )
  }
  // If no workflow found
  if (!loading && !assessment) {
    return <h2>Workflow not found</h2>
  }

  // Destructure workflow
  const { name, documents, description: dangerousDescriptionHTML } = assessment

  // Sanitise description HTML
  const sanitizedHTML = sanitizeHtml(dangerousDescriptionHTML)
  const description = <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>

  return (
    <Page>
      <Page.Section>
        <div className={c.page}>
          <div>
            <CloseHeader title={name} onClose={() => history.goBack()} />
            <div className={c.content}>
              <div className={c.description}>{description}</div>
              {Array.isArray(documents) &&
                documents.map(doc => (
                  <FileInfo
                    key={doc.url}
                    className='mt-2'
                    fileName={doc?.title}
                    fileSize={doc?.size}
                    mimeType={doc?.mimeType}
                    url={doc?.url}
                    info={doc?.info}
                  />
                ))}
            </div>
          </div>

          {/* Bottom */}
          <div className={c.bottom}>
            <Button onClick={() => history.push(`/assessments/${id}/run`)}>Start</Button>
          </div>
        </div>
      </Page.Section>
    </Page>
  )
}

/*
const mapStateToProps = state => ({
  assessments: state.workflows.assessments,
  fetched: state.workflows.fetched,
})

const mapDispatchToProps = dispatch => ({
  fetchWorkflows: () => dispatch(workflowActions.fetchWorkflows()),
})
*/

export default AssessmentBegin; //connect(mapStateToProps, mapDispatchToProps)()
