import Page from "../components/Page"
import { useEffect, useState } from "react"
import axios from "axios";
import Select from "aius-comp/Select";

const WorkflowRunAssignment = ({workflow_run_id, user_id}) => {
    const [userOptions, setUserOptions] = useState([]);
    const [defaultUser, setDefaultUser] = useState(user_id)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/locations/users`).then( res => {
            setUserOptions(res.data.users.map((user) => {
                return {
                    label : user.firstName + ' ' + user.lastName,
                    value : user.id
                }
            }))  
        })  
    }, [])

    const handleOnchange = (event) => {
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/workflow-run-assign-user/${workflow_run_id}`, {user:event.value}).then(res => {
            //console.log(res);
        })

        setDefaultUser(event.value);
    } 

    return (
        <Page.Section className="mt-2">
            <Select 
                label='Assigned User'
                options={userOptions}
                onChange={handleOnchange}
                value={userOptions ? userOptions.filter((user) => user.value === defaultUser ): null}
            />
            <span></span>
        </Page.Section>
    );
};

export default WorkflowRunAssignment;
