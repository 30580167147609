import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'aius-comp/Button'
import Badge from "aius-comp/Badge"

const ErrorPage = (props) => {
  const { title, description } = props
  const history = useHistory()
  return (
    <div className='d-flex flex-column justify-content-center align-items-center flex-gap-1 h-100'>
      <Badge pill variant='warning'>
        Unexpected bahavior
      </Badge>
      <h2>{title}</h2>
      <p>{description}</p>
      <Button onClick={() => history.goBack()}>Go Back</Button>
    </div>
  )
}

export default ErrorPage
