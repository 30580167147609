import React, { useEffect, useRef } from "react"
import { Chart, PieController, ArcElement } from "chart.js"
import c from "./IncidentPieChart.module.scss"

/*
  Pie chart used for incident analytics
*/

Chart.register(PieController, ArcElement)

const RiskMaturityChart = ({ value }) => {
  const chartRef = useRef()

  useEffect(() => {
    const ref = chartRef.current.getContext("2d")
    const colors = ["#D2D2D2", "#9DEB9C", "#2E7CF6", "#FCDC00", "#FE9200", "#FC3F3F"]
    var color = colors[5]
    if (value < 64) color = colors[4]
    if (value < 51) color = colors[3]
    if (value < 38) color = colors[2]
    if (value < 24) color = colors[1]
    if (value < 12) color = colors[0]


    const data = {
      datasets: [
        {
          label: "Incidents",
          data: [value, 75-value],
          backgroundColor: [ color, '#F2F2F2'],
        },
      ],
    }
    const chart = new Chart(ref, {
      type: "pie",
      data: data,
      options: {
        responsive: true,
      },
    })
    return () => chart.destroy()
  }, [value])

  return (
    <div className={c.chart}>
      <canvas ref={chartRef}></canvas>
      <div className={c.middle}></div>
    </div>
  )
}

export default RiskMaturityChart
