import React, { useState } from "react"
import PropTypes from "prop-types"
import uniqueID from "lodash.uniqueid"
import cn from "classnames"
import c from "./Dropzone.module.scss"
import ListItem from '../components/ListItem'
import Document from "../components/Document"
import crossIcon from './images/icons/trash-icon.png'

/** 
  `import Dropzone from "aius-comp/Dropzone"` 

  - Files can be drag & dropped or selected once clicked
  - Array of selected files are passed to the 'onChange' callback (https://developer.mozilla.org/en-US/docs/Web/API/File) 
**/

const Dropzone = props => {
  const callback = props.onChange
  const removeCallback = props.onRemoveAll
  const ID = uniqueID("file-import-")
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleSelect = event => {
    try {
      event.persist()
      const documents = Array.from(event.currentTarget.files)
      setSelectedFiles(documents);
      callback(documents)
    } catch (e) {
      console.error(e)
    }
  }

  const handleDrop = event => {
    try {
      event.persist()
      const documents = Array.from(event.dataTransfer.files)
      setSelectedFiles(documents);
      callback(documents)
    } catch (e) {
      console.error(e)
    } finally {
      event.preventDefault()
    }
  }

  


  const FileListing = props => {
    return props.selectedFiles.map( (file, index) => { return (
        <ListItem  
          className={c.listfiles}  
          bordered="true" key={index}>
            <Document>{file.name}</Document>
            <img src={crossIcon} className={c.icon} onClick={removeFileHandler} alt=''/>
        </ListItem>)}
      );
  }

  const removeFileHandler = () => {
    setSelectedFiles([]);
    removeCallback([])
  }

  return (
    <>
    <div className={cn(c.dropzone, props.className)}>
      <label htmlFor={ID} className={c.label} onDrop={e => handleDrop(e)} onDragOver={e => e.preventDefault()}>
        { !props.selected ? (
          <div>
            Select file
          </div>
        ) : (
          <div>
            {props.selected}
          </div>
        )}
      </label>
      <input id={ID} className={c.default} type='file' multiple={props.multiple} accept={props.accept} onChange={e => handleSelect(e)} />
    </div>
    <FileListing selectedFiles={selectedFiles}/>
    </>
  )
}

Dropzone.propTypes = {
  onChange: PropTypes.func.isRequired,
  onRemoveAll: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  showCounter: PropTypes.bool,
  selected: PropTypes.object,
}

Dropzone.defaultProps = {
  onChange: files => console.error(`[Dropzone] Default callback, no 'onChange' provided.`),
  onRemoveAll: value => console.error(`[Dropzone] Default callback, no 'onRemoveAll' provided.`),
  multiple: false,
  accept: "*",
  showCounter: false,
  selected: undefined,
}

export default Dropzone
