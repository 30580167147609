import React, { useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import c from "./DraggableBase.module.scss"

/*
  Draggable base component on which several components build on.
  Used in Hub page.
*/

const DraggableBase = ({ children }) => {
  const [height, setHeight] = useState(-200)
  const base = useRef()

  // Dynamically determine height of component
  useEffect(() => {
    try {
      setHeight(-(base.current.parentElement.clientHeight - window.screen.height))
    } catch (e) {
      console.warn(e)
      setHeight(-200)
    }
  }, [])

  return (
    <Draggable axis='y' bounds={{ bottom: 400, top: height-200 }} defaultPosition={{ x: 0, y: 400 }}>
      <div className={c.draggable}>
        <div className={c.base} ref={base}>
          {children}
        </div>
      </div>
    </Draggable>
  )
}

export default DraggableBase
