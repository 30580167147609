import React, { useEffect, useRef } from "react"
import { Chart, PieController, ArcElement } from "chart.js"
import c from "./IncidentPieChart.module.scss"

/*
  Pie chart used for incident analytics
*/

Chart.register(PieController, ArcElement)

const RiskProfileChart = ({ value , color }) => {
  const chartRef = useRef()
  //const empty = (minor + major + severe + crisis) === 0 ? [1]: [0]
  useEffect(() => {
    const ref = chartRef.current.getContext("2d")
    const data = {
      datasets: [
        {
          label: "Incidents",
          data: [value, 100 - value],
          backgroundColor: [ color, '#F2F2F2'],
        },
      ],
    }
    const chart = new Chart(ref, {
      type: "pie",
      data: data,
      options: {
        responsive: true,
      },
    })
    return () => chart.destroy()
  }, [value, color])

  return (
    <div className={c.chart}>
      <canvas ref={chartRef}></canvas>
      <div className={c.middle}></div>
    </div>
  )
}

export default RiskProfileChart
