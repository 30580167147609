import types from "../types/workflows"
//import axios from "axios"

export const fetchWorkflows = () => {
  return dispatch => {
    /*
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/workflows`)
      .then(res => {
        dispatch({ type: types.fetchWorkflows, payload: res.data })
      })
      .catch(err => console.error(err))
    */
    dispatch({ type: types.fetchWorkflows, payload: [] })  
  }
}

export const removeWorkflows = () => {
  return { type: types.removeWorkflows }
}

/*
export default {
  fetchWorkflows,
}
*/
