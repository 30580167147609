import React from 'react'
import cn from 'classnames'
import c from './MenuButton.module.scss'

const MenuButton = ({ active, children, className, ...rest}) => {
  const buttonClasses = cn(
    c.button,
    {
      [c.active]: active,
    },
    className
  )
  return (
    <div {...rest} className={buttonClasses}>
      {children}
    </div>
  )
}

export default MenuButton