import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import Page from "../components/Page";
import ListItem from "../components/ListItem";
import { GoogleMap, LoadScript, Circle } from "@react-google-maps/api";
import closeIcon from "../images/icons/close-icon-white.svg";
import axios from "axios";
import c from "./MultipleHotelsAnalytics.module.scss";
import { connect } from "react-redux";
import HotelRiskItem from "../components/HotelRiskItem";
import HotelRiskItemDelta from "../components/HotelRiskItemDelta"
import cn from "classnames";

/* 
  Analytics page for showing multiple locations

  TODO: 
  - Determine the bounding box of all incident coordinates and set center and zoom level accordingly
*/

const MultipleHotelsRisks = ({ notifications }) => {
    const map = useRef();
    const history = useHistory();
    const [severity] = useState();
    const [hotelStatistics, setHotelStatistics] = useState([]);
    const [zoom, setZoom] = useState();
    const [days] = useState(7);
    const GMAP_KEY = process.env.REACT_APP_GMAPS_API_KEY;

    // Get incident statistics by location
    useEffect(() => {
        const data = { params: { severity, days } };
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/incidents/locations`,
                data
            )
            .then((res) => setHotelStatistics(res.data.locations))
            .catch((e) => console.error(e));
    }, [severity, days]);

    const center = {
        lat: 53.0,
        lng: 1.4,
    };

    const mapStyles = {
        width: "100%",
        height: "100%",
    };

    const mapOptions = {
        disableDefaultUI: true,
    };

    const radius = Math.min(32000, 15000 / (zoom / 5) ** 3.5);

    return (
        <Page>
            {/* Top Cover */}
            <div className={c.cover}>
                <div className={c.map}>
                    <LoadScript googleMapsApiKey={GMAP_KEY}>
                        <GoogleMap
                            ref={map}
                            mapContainerStyle={mapStyles}
                            center={center}
                            zoom={2}
                            options={mapOptions}
                            onZoomChanged={() =>
                                setZoom(map?.current?.state?.map?.zoom ?? 5)
                            }
                        >
                            {hotelStatistics?.map((hotel) => {
                                const coordinates = {
                                    lat: hotel.latitude,
                                    lng: hotel.longitude,
                                };
                                const { incidents } = hotel;
                                const severity =
                                    incidents.crisis > 0
                                        ? 4
                                        : incidents.severe > 0
                                        ? 3
                                        : incidents.major > 0
                                        ? 2
                                        : incidents.minor > 0
                                        ? 1
                                        : 0;
                                const colors = [
                                    "#FFFFFF",
                                    "#71ADBB",
                                    "#E0B259",
                                    "#d145ca",
                                    "#FF4A4A",
                                ];
                                const options = {
                                    strokeColor: "#FFFFFF",
                                    strokeWeight: 1.5,
                                    fillColor: colors[severity],
                                    strokeOpacity: severity > 0 ? 0.9 : 0,
                                    fillOpacity: severity > 0 ? 0.9 : 0,
                                };
                                return (
                                    <Circle
                                        key={hotel.id}
                                        center={coordinates}
                                        radius={radius}
                                        visible={true}
                                        options={options}
                                    />
                                );
                            })}
                        </GoogleMap>
                    </LoadScript>
                </div>
                <div className={c.closeContainer}>
                    <img
                        src={closeIcon}
                        className={c.close}
                        onClick={() => history.push("/")}
                        alt=""
                    />
                </div>
            </div>

            <Page.Hr />


            <ListItem>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <h3>AIUS RISK MATURITY RATING&trade;</h3>
                </div>
            </ListItem>
            <Page.Hr />

            <Page.Section>
                <div
                    className={cn(
                        c.listingOnTab,
                        "d-flex flex-gap-3 pt-4 pb-4"
                    )}
                >
                    {hotelStatistics
                        ?.sort(
                            (a, b) =>
                                b.incidents.crisis +
                                b.incidents.severe +
                                b.incidents.major +
                                b.incidents.minor -
                                a.incidents.crisis -
                                a.incidents.severe -
                                a.incidents.major -
                                a.incidents.minor
                        )
                        .map((hotel) => (
                            <HotelRiskItem 
                                className={c.eachItemOnTab} 
                                id={hotel.id} key={hotel.id} 
                                name={hotel.name} 
                                city={hotel.city} 
                                risks={hotel.risks}
                                onClick={() =>
                                    history.push(
                                        `/risks/${hotel.id}`,
                                        { hotel }
                                    )
                                }
                                />                            
                        ))}
                </div>
            </Page.Section>

            <Page.Hr />

            {/* List | Risk Changes */}
            <Page.Section>
                <div
                    className={cn(
                        c.listingOnTab,
                        "d-flex flex-gap-3 pt-4 pb-4"
                    )}
                >
                    {hotelStatistics
                        ?.sort(
                            (a, b) =>
                                b.incidents.crisis +
                                b.incidents.severe +
                                b.incidents.major +
                                b.incidents.minor -
                                a.incidents.crisis -
                                a.incidents.severe -
                                a.incidents.major -
                                a.incidents.minor
                        )
                        .map((hotel) => {
                            if (hotel.risks.delta < 0 || hotel.risks.delta > 0 ) {
                                return (
                                    <HotelRiskItemDelta 
                                        className={c.eachItemOnTab} 
                                        id={hotel.id} key={hotel.id} 
                                        name={hotel.name} 
                                        city={hotel.city} 
                                        risks={hotel.risks}
                                        />
                                )
                            } else { return (null)}
                        })
                    }
                </div>
            </Page.Section>

            
        </Page>
    );
};

const mapStateToProps = (state) => ({
    notifications: state.system.notifications.sort(sortBySeverity),
});

const sortBySeverity = (a, b) => {
    const severities = { SEVERE: 0, MAJOR: 1, MINOR: 2 };
    if (severities[a.severity] > severities[b.severity]) {
        return 1;
    } else if (severities[a.severity] < severities[b.severity]) {
        return -1;
    } else {
        return 0;
    }
};

export default connect(mapStateToProps)(MultipleHotelsRisks);
