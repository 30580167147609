import React from 'react'
import PropTypes from 'prop-types'
import c from './Loading.module.scss'
import cn from 'classnames'

/**
  `import Loading from "aius-comp/Loading"` 
**/

const Loading = ({ size }) => {
  const sizes = {
    'small': [c.small],
    'normal': [c.normal],
    'large': [c.large],
  }

  const classes = cn([c.loading, sizes[size]])

  return (
    <div className={classes}>
      <div className={c.bubble}></div>
    </div>
  )
}

Loading.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'large'])
}

Loading.defaultProps = {
  size: 'normal'
}

export default Loading
