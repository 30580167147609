import React from "react"
import PropTypes from 'prop-types'
import cn from 'classnames'
import c from "./Search.module.scss"
import searchIcon from './images/icons/Search.svg'

/**
  `import Search from "aius-comp/Search"` 

  - Default input element of type "search" 
  - Accepts same parameters (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/search)
**/

const Search = ({transparent, ...props}) => {
  const classes = cn([c.container, {[c.transparent]: transparent}])

  return (
    <div className={classes}>
      <img src={searchIcon} className={c.icon} alt=''/>
      <input type="search" {...props} className={c.search} placeholder="Search" />
    </div>
  )
}

Search.defaultProps = {
  transparent: true,
}

Search.propTypes = {
  transparent: PropTypes.bool,
}

export default Search