import React from "react"
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux"
import menuIcon from '../images/icons/menu.svg'
import Bell from './Bell'
import Envelope from './Envelope'
import { systemActions } from "../store/actions"
import c from "./Header.module.scss"

/*
  Header component used within Page wrapper.
  Shows menu icon, notification icon and profile picture.
*/

const Header = ({ 
    profileImageURL, 
    firstName, 
    lastName, 
    showNotifications,
    bellActive,
    notificationsOpen,
    msgCount,
    ...props
  }) => { 
  const history = useHistory()

  // Show profile color based on first character of first name
  const profileColor = Math.max(0, Math.min(255, firstName.charCodeAt(0))) || 128
  const whichColor = profileColor % 3
  const baseColor = 36
  const backgroundColor = whichColor === 0 
    ? `rgb(${profileColor}, ${baseColor}, ${baseColor})` : whichColor === 1
    ? `rgb(${baseColor}, ${profileColor}, ${baseColor})` : `rgb(${baseColor}, ${baseColor}, ${profileColor})`
    
  // Show user's initials
  const initials = <span>{firstName[0] + lastName[0]}</span>

  return (
    <div className={c.header}>
      {/* Left Side */}
      <div>
        <img onClick={props.onMenuOpen} className={c.menu} src={menuIcon} alt=''/>
      </div>

      {/* Right side */}
      <div className='d-flex align-items-center flex-gap-2'>
        <Envelope count={msgCount} active={msgCount > 0} onClick={() => history.push('/mail/inbox')}/>
        <Bell active={bellActive} onClick={() => !notificationsOpen && showNotifications()} />
        <div onClick={() => history.push('/profile')} className={c.avatar} style={profileImageURL ? null : { backgroundColor: backgroundColor }}>
          {profileImageURL ? <img className={c.image} src={profileImageURL} alt=''/> : initials}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  profileImageURL: state.user.profileImageURL,
  bellActive: state.system?.notifications?.length > 0,
  notificationsOpen: state.system.notificationsOpen,
  msgCount: state.system.msgCount,
})

const mapDispatchToProps = dispatch => ({
  showNotifications: () => dispatch(systemActions.showNotifications())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)

