import React from 'react'
import NotificationIcon from './NotificationIcon'
import c from './RiskItem.module.scss'

const RiskItem = ({ title, subtitle, icon,  children }) => {
  
  return (
    <div className={c.item}>
      <NotificationIcon icon={icon} className={c.icon} />
      <div className={c.content}>
        <div className={c.title}>{title}</div>
        <div className={c.subtitle}>{subtitle}</div>
        { children && <div>{children}</div> }
      </div>
    </div>
  )
}

export default RiskItem
