import Page, { useMenu } from "../components/Page"
import Popup from "../components/ui/Popup"
import PreliminaryForm from "../components/assessments/PreliminaryForm"
import AssessmentHead from "../components/assessments/AssessmentHead"
import NewAssessmentStep from "../components/assessments/NewAssessmentStep";


const CreateAssessmentStep = (props) => {
    const [openMenu, controls] = useMenu();
    return (
        <Page {...controls}>
            <Page.Top onMenuOpen={openMenu}>Create Steps</Page.Top>
            <Page.Section>
                <Popup defaultState="open">
                    <PreliminaryForm />
                </Popup>
                <AssessmentHead />
                <NewAssessmentStep content={'Add Question'} goto={'/assessments-question'}/>
            </Page.Section>
        </Page>
    );
};

export default CreateAssessmentStep;
