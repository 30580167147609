import React from 'react'
import c from './Menu.module.scss'
import cn from 'classnames'
import closeIcon from '../images/icons/cancel.svg'

const Menu = props => {
  const { open, onMenuClose, children } = props

  const menuClasses = cn([
    c.menu,
    {
      [c.open]: open
    }
  ])
  return (
    <div className={menuClasses}>
      {/* Top */}
      <div className={c.top}>
        <img className={c.close} src={closeIcon} onClick={onMenuClose} alt=''/>
      </div>
      <div className="d-flex flex-column flex-gap-1 justify-content-start" >
        {children}
      </div>
    </div>
  )
}

export default Menu
