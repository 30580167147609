import React, { useState } from 'react'
import { useHistory } from "react-router"
import { hotelActions, systemActions, userActions, workflowActions } from '../store/actions'
import { connect } from 'react-redux'
import capitalise from 'aius-fn/capitalise'
import Can from './Can'
import arrowIcon from "../images/icons/arrow-left.svg"
import Menu from './Menu'
import Header from './Header'
import MenuButton from "./MenuButton"
import RightMenu from './RightMenu'
import NotificationItem from './NotificationItem'
import Dialog from './Dialog'
import cn from 'classnames'
import c from "./Page.module.scss"
import Cannot from './Cannot'

/*
  Page wrapper used for most pages.
  - Handles sign-out
  - Page menu
  - Notification menu

  TODO: 
  - Use current path to highlight current page in side menu
  - Remove temp. user as display for notifications
*/

const Page = ({
  children, 
  isOpen, 
  closeMenu, 
  dark = false, 
  removeUser, 
  removeWorkflows, 
  removeHotels,
  notifications,
  removeNotification,
  flashMessage,
  notificationsOpen,
  hotels,
  ...props 
}) => {
  const history = useHistory()
  const [showSignout, setShowSignout] = useState(false)
  //const path = getPath(history.location.pathname, 4)
  
  const pageClasses = cn(
    c.page, 
    { 
      [c.dark]: dark ,
      [c.noscroll]: notificationsOpen
    }
  )

  const signOut = () => {
    removeUser()
    removeWorkflows()
    removeHotels()
  }

  const tempUser = {
    firstName: props.firstName,
    lastName: props.lastName,
    profileImageURL: props.profileImageURL,
  }

  const snoozeFn = (notification) => {
    removeNotification(notification.id, true)
    flashMessage(`${capitalise(notification.type)} snoozed for 24hrs ⏳`)
  }

  return (
    <div className={pageClasses}>

      {/* Main content */}
      {children}

      {/* Toggleable Page Menu */}
      <Menu open={isOpen} onMenuClose={closeMenu}>
        <MenuButton onClick={() => history.push("/")}>Hub</MenuButton>

        <Can perform='hotels:aggregate'>
          <MenuButton onClick={() => history.push("/hotels")}>My Hotels</MenuButton>
        </Can>
        <Cannot perform='hotels:aggregate'>
          <Can perform='hotels-menu:view'>
            <MenuButton onClick={() => history.push("/hotels")}>My Hotel{hotels.length === 1 ?? 's'}</MenuButton>
          </Can>
        </Cannot>

        <Can perform='hotels:aggregate'>
          <MenuButton onClick={() => history.push("/hotels/risks")}>Risks</MenuButton>
        </Can>

        <Can perform='documents-menu:view'>
          <MenuButton onClick={() => history.push("/documents")}>Documents</MenuButton>
        </Can>
        <Can perform='assessments-menu:view'>
          <MenuButton onClick={() => history.push("/assessments")}>Assessments</MenuButton>
        </Can>
        <Can perform='reports-menu:view'>
          <MenuButton onClick={() => history.push("/reports")}>Reports</MenuButton>
        </Can>
        <MenuButton onClick={() => history.push("/training")}>Training</MenuButton>
        <MenuButton onClick={() => history.push("/tasks")}>Tasks</MenuButton>
        <MenuButton onClick={() => {closeMenu(); window.FreshworksWidget('open')}}>Help & Support</MenuButton>
        <MenuButton onClick={() => setShowSignout(true)}>Sign Out</MenuButton>
      </Menu>

      {/* Toggleable Notification Menu */}
      <RightMenu isOpen={notificationsOpen} close={props.hideNotifications}>
        {notifications?.map(notification => (
          <NotificationItem
            key={notification.id}
            prefix={new Date(notification.principal.raisedAt).toDateString()}            
            title={notification.title}
            subtitle={notification.message}
            severity={notification.severity}
            triggeredBy={notification.external ? undefined : tempUser}
            onSnooze={notification.snoozeable ? () => snoozeFn(notification) : null}
            actionType={notification.actionType}
            actionId={notification.actionId}
            profileMessageId={notification.profileMessageId}
            onAcknowledge={props.hideNotifications}
          />
        ))}
      </RightMenu>
      
      {/* Signout Dialog */}
      <Dialog title='Sign Out' visible={showSignout} onSuccess={signOut} onCancel={() => setShowSignout(false)}>
        Do you wish to sign out ?
      </Dialog>

    </div>
  )
}

const Top = ({ children, back = false, onBack, ...rest }) => {
  return (
    <div className={c.top}>
      <Header {...rest} />
      <div className={c.title}>
        {back && <img src={arrowIcon} onClick={onBack} alt=''/>}
        <h1>{children}</h1>
      </div>
    </div>
  )
}

const Section = ({ children, className }) => {
  return <div className={cn(c.section, className)}>{children}</div>
}

const Hr = ({ className, ...rest }) => <div className={cn([c.divider, className])} {...rest}></div>

export const useMenu = (initial = false) => {
  const [isOpen, setMenuOpen] = useState(initial)
  const closeMenu = () => setMenuOpen(false)
  const openMenu = () => setMenuOpen(true)
  return [openMenu, { closeMenu, openMenu, isOpen }]
}

Page.Top = Top
Page.Section = Section
Page.Hr = Hr

const mapStateToProps = state => ({
  notificationsOpen: state.system.notificationsOpen,
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  profileImageURL: state.user.profileImageURL,
  notifications: state.system.notifications,
  hotels: state.hotels.list
})

const mapDispatchToProps = dispatch => ({
  removeUser: () => dispatch(userActions.removeUser()),
  removeWorkflows: () => dispatch(workflowActions.removeWorkflows()),
  removeHotels: () => dispatch(hotelActions.removeHotels()),
  hideNotifications: () => dispatch(systemActions.hideNotifications()),
  removeNotification: (id) => dispatch(systemActions.removeNotification(id)),
  flashMessage: (message) => dispatch(systemActions.flashMessage(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Page)
