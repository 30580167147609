import React from "react"
import Page, { useMenu } from '../components/Page'
import RiskRecommendationItem from "../components/RiskRecommendationItem"


const attribution = {textAlign: 'center'}

const RiskRecommendations = (props) => {
  const [openMenu, controls] = useMenu()

  return (
    <Page {...controls}>
      <Page.Top onMenuOpen={openMenu}><h1>Political Risk - Terrorism & Political Violence</h1><br/><h3>Recommendations for critical risk level</h3></Page.Top>


      <div>
          <RiskRecommendationItem
            key='1'
            title='SITE & ACCESS SECURITY'
            severity='critical'
            description='A securable perimeter of at least 2 metres <br/><br/>Perimeter lighting <br/><br/>CCTV and intruder detection systems covering all vulnerable points on the perimeter <br/><br/>K or PAS 68 rated ramps or rising bollards with emergency activation at all site vehicle entrances <br/><br/>Vehicle search and screening equipment at each site entrance <br/><br/>Where space permits, traffic calming measures on the approach to site entrances <br/><br/>Security lighting at all site access points <br/><br/>Emergency alert facility at each site access point <br/><br/>CCTV covering all site entrances <br/><br/>Adhere to all other company security standards and procedures'
          />
          <RiskRecommendationItem
            key='2'
            title='BUILDING SECURITY'
            severity='critical'
            description='On new builds blast resilience should be included in the property design. For established properties retro fitting of building structural blast resilience measures should be deployed as appropriate <br/><br/>Crash rated bollards to prevent vehicles being driven into the building <br/><br/>Blast Resistant glazing on all external facing windows up to the 7th floor <br/><br/>Central locking system to enable emergency building lockdown <br/><br/>People and luggage screening facilities at all operational building entrances including <br/><br/>Goods Receiving. Hardened large luggage screening area <br/><br/>Securable underground carpark shutters <br/><br/>Good Receiving Areas securable by shutters <br/><br/>CCTV deployed at all building entrances/exits <br/><br/>CCTV monitored 24/7 <br/><br/>Adhere to all other company security standards and procedures'
          />
          <RiskRecommendationItem
            key='3'
            title='PEOPLE'
            severity='critical'
            description='Directly employed Security Director who is appropriately experienced and trained <br/><br/>Directly employed Deputy Security Director who is appropriately experienced and trained <br/><br/>Directly employed Security Supervisors who are appropriately experienced and trained <br/><br/>Where the law permis the deployment of armed security guards either directly employed or contracted from an estblished and reliable company <br/><br/>Fully manned security officer team <br/><br/>Female Security Guards on each shift to conduct female screening <br/><br/>Surge guarding capability in the event of heightened security <br/><br/>Regular intelligence and security liaison with local authorities <br/><br/>Security screening of vendors and contractors <br/><br/>Security screening of all employees <br/><br/>Adhere to all other company security standards and procedures'
          />          
          <RiskRecommendationItem
            key='4'
            title='TRAINING'
            severity='critical'
            description='Enhanced Security Awareness for all staff <br/><br/>Hostile Surveillance Identification & Disruption <br/><br/>Vehicle Search & Screening <br/><br/>People and Luggage Search & Screening <br/><br/>Active Shooter <br/><br/>Hotel and Site Lock Down <br/><br/>Hotel Evacuation & Shelter in Place <br/><br/>Mass Casualty Triage & Treatment <br/><br/>Emergency Service Reception and Co-operation <br/><br/>Adhere to all other company security standards and procedures'
          />          

      </div>
      <div style={attribution}><h3>Source: Northpoint International</h3></div>

    </Page>
  )
}


export default RiskRecommendations