import React, { useState, useEffect } from "react"
import { useHistory } from "react-router"
import ListItem from './ListItem'
import ProgressTaskItem from "./ProgressTaskItem"
import BaseTaskItem from "./BaseTaskItem"
import DraggableBase from "./DraggableBase"
import DraggableTouch from "./DraggableTouch"
import axios from "axios"

/*
  Shows summary of hotel portfolio.
*/

const DashboardSummary = () => {
  const history = useHistory()
  const [summaries, setSummaries] = useState([])
  const [reports, setReports] = useState([])

  // Get assessment statistics
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/assessments/summary`)
      .then(res => {
        setSummaries(res.data.summaries ?? [])
      })
      .catch(e => {
        console.error(e)
      })
    return () => { setSummaries([]) }  
  }, [])

  // Get incidents for last 7 days
  useEffect(() => {
    const data = {
      params: {
        days: 7,
        useCreatedAt: true,
      },
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/incidents`, data)
      .then(res => {
        setReports(res.data?.incidents ?? [])
      })
      .catch(e => {
        console.error(e)
      })
    return () => { setReports([]) }  
  }, [])

  return (
    <DraggableBase>
      <header className='d-flex justify-content-center pt-5 pb-5'>
        <h1><img width="32px" height="32px" src="/favicon-32x32.png" alt="AIUS icon"/>Summary</h1>
      </header>

      {/* Status of assessments at portfolio locations */}
      <section className='pb-5'>
        <ListItem><h1>Assessments</h1></ListItem>
        {summaries?.map(summary => (
          <DraggableTouch key={summary.name} onClick={() => history.push(`/assessments/c/${summary.categoryId}`)}>
            <ProgressTaskItem
              title={summary.name}
              subtitle={`${summary.completed} / ${summary.assigned} completed from ${summary.total} hotels`}
              description={`${summary.failed} failed`}
              current={summary.passed}
              max={summary.total}
            />
          </DraggableTouch>
        ))}
      </section>

      {/* Incidents that have occurred in last 7 days at portfolio locations */}
      <section>
        {reports?.length > 0 && <ListItem><h1>Reports</h1></ListItem>}
        {reports?.map(report => (
          <DraggableTouch key={report?.id} onClick={() => history.push(`/reports/${report?.id}`)}>
            <BaseTaskItem title={report?.workflow?.name} subtitle={report?.title} />
          </DraggableTouch>
        ))}
      </section>

      {/* Alerts */}
      <section></section>
    </DraggableBase>
  ) 
}

export default DashboardSummary
