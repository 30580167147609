import React from 'react'
import { connect } from 'react-redux'
import c from './FlashMessage.module.scss'

/*
  Container for system messages.
  Used in App and accessed through Redux
*/

const FlashMessage = ({ messages }) => {
  return (
    <div className={c.container}>
      {Array.isArray(messages) && messages.map((message, i) => (
        <Message key={`${message}-${i}`}>{message}</Message>
      ))} 
    </div>
  )
}

const Message = ({ children }) => (
  <div className={c.message}>
    {children}
  </div>
)

const mapStateToProps = state => ({
  messages: state.system.messages
})

export default connect(mapStateToProps)(FlashMessage)
