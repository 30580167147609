import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import Can from "../components/Can"
import Page, { useMenu } from '../components/Page'
import filterIcon from '../images/icons/filter.svg'
import ReportListItem from "../components/ReportListItem"
import RightMenu from "../components/RightMenu"
import ControlSelect from "../components/ControlSelect"
import axios from "axios"
import IconItem from "../components/IconItem"
import Select from "aius-comp/Select"
import Button from "aius-comp/Button"
import c from "./IncidentReports.module.scss"

const IncidentReports = ({ hotels }) => {
  const [openMenu, controls] = useMenu()
  const [filterOpen, setFilterOpen] = useState(false)
  const [incidents, setIncidents] = useState([])
  const history = useHistory()

  // Query parameter options
  const [uploaders, setUploaders] = useState([])
  const [categories, setCategories] = useState([])

  // Query parameters
  const [days, setDays] = useState(7)
  const [uploaderID, setUploaderID] = useState()
  const [hotelID, setHotelID] = useState()
  const [categoryID, setCategoryID] = useState()

  // Get incidents
  useEffect(() => {
    const data = {
      params: {
        days: days || undefined,
        reporterID: uploaderID,
        locationID: hotelID,
        categoryID: categoryID,
      },
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/incidents/full`, data)
      .then(res => setIncidents(res.data.incidents))
      .catch(e => console.error(e))
  }, [days, uploaderID, categoryID, hotelID])

  // Get users of assigned locations
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/locations/users`)
      .then(res => setUploaders(res.data.users))
      .catch(e => console.warn(e))
  }, [])

  // Get users of assigned locations
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/incidents/categories`)
      .then(res => setCategories(res.data.categories))
      .catch(e => console.warn(e))
  }, [])

  const defaultOption = { value: undefined, label: "All" }
  
  const userOptions = [
    defaultOption,
    ...(uploaders?.map(user => ({
      value: user.id,
      label: <IconItem text={`${user.firstName} ${user.lastName}`} icon={user.profileImageURL} />,
    })) ?? []),
  ]

  const hotelOptions = [
    defaultOption,
    ...(hotels?.map(hotel => ({
      value: hotel.id,
      label: <IconItem text={hotel.name} icon={hotel.coverImageURL} />,
    })) ?? []),
  ]

  const categoryOptions = [
    defaultOption,
    ...(categories?.map(category => ({
      value: category.id,
      label: category.category,
    })) ?? []),
  ]

  return (
    <Page {...controls}>
      <Page.Top onMenuOpen={openMenu}>Reports</Page.Top>

      {/* Controls */}
      <div className={c.controls}>
        <div className='d-flex flex-gap-2'>
          <ControlSelect onChange={e => setDays(e.target.value)} defaultValue='7'>
            <option value='7'>Last 7 days</option>
            <option value='14'>Last 14 days</option>
            <option value='30'>Last 30 days</option>
            <option value='90'>Last 3 months</option>
            <option value='365'>Last 1 year</option>
            <option value=''>All</option>
          </ControlSelect>
          <img src={filterIcon} onClick={() => setFilterOpen(true)} alt=''/>
        </div>
        <Can perform='incidents-report-new:view'>
          <Button size='small' variant='info' onClick={() => history.push("/actions")}>
            Add New Report
          </Button>
        </Can>
      </div>

      {/* List | Reports */}
      <div>
        {incidents.map(incident => (
          <ReportListItem
            key={incident.id}
            category={incident?.workflow?.categories?.[0].category}
            type={incident?.workflow?.name}
            title={incident?.title}
            status={incident?.status}
            subtitle={<IconItem icon={incident?.locations[0]?.coverImageURL} text={incident?.locations[0]?.name} />}
            date={new Date(incident?.createdAt).toDateString()}
            severity={incident?.severity?.toLowerCase()}
            iconText={`${incident?.createdBy?.firstName} ${incident?.createdBy?.lastName}`}
            iconURL={incident?.createdBy?.profileImageURL}
            onClick={() => history.push(`/reports/${incident?.id}`)}
          />
        ))}
      </div>

      {/* Filter Menu */}
      <RightMenu isOpen={filterOpen} close={() => setFilterOpen(false)}>
        <RightMenu.Section>
          <div className='d-flex flex-column flex-gap-2'>

            <Can perform='incidents-filter-occurred-at:view'>
              <Select
                label='Occurred At'
                options={hotelOptions}
                onChange={option => setHotelID(option.value)}
                isSearchable={false}
                defaultValue={defaultOption}
              />
            </Can>

            <Can perform='incidents-filter-reported-by:view'>
              <Select
                label='Reported By'
                options={userOptions}
                onChange={option => setUploaderID(option.value)}
                isSearchable={false}
                defaultValue={defaultOption}
              />
            </Can>

            <Can perform='incidents-filter-category:view'>
              <Select
                label='Category'
                options={categoryOptions}
                onChange={option => setCategoryID(option.value)}
                isSearchable={false}
                defaultValue={defaultOption}
              />
            </Can>
          </div>
        </RightMenu.Section>
      </RightMenu>
    </Page>
  )
}

const mapStateToProps = state => ({
  hotels: state.hotels.list
})

export default connect(mapStateToProps)(IncidentReports)