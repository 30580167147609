import React, { useState, useEffect } from "react"
import Button from 'aius-comp/Button'
import { useHistory, useParams } from 'react-router-dom'
import Page, { useMenu } from '../components/Page'
import axios from "axios"
import { connect } from 'react-redux'
import ErrorPage from "aius-comp/ErrorPage"
import Loading from "aius-comp/Loading"
import ListItem from "../components/ListItem"
import IconItem from "../components/IconItem"
import calendarIcon from '../images/icons/calendar.svg'
import modifyIcon from '../images/icons/modify.svg'
import downloadIcon from '../images/icons/download.svg'
import uploadIcon from '../images/icons/upload.svg'
import Document from "../components/Document"
import c from './DocumentDetails.module.scss'
import { systemActions } from "../store/actions"

const DocumentDetails = props => {
  const [openMenu, controls] = useMenu()
  const [loading, setLoading] = useState(true)
  const [document, setDocument] = useState()
  const [logs, setLogs] = useState([])
  const history = useHistory()
  const { id: documentID } = useParams()

  // Get document information
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/documents/${documentID}`)
      .then(res => {
        const document = res.data?.document
        setDocument(document)
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [documentID])

  // Get document logs
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/documents/${documentID}/logs`, { params: { take: 5 } })
      .then(res => {
        const logs = res.data?.logs ?? []
        setLogs(logs)
      })
      .catch(e => {
        console.error(e)
      })
  }, [documentID])

  const deleteDocument = () => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/documents/${documentID}`)
      .then(() => {
        props.flashMessage("Document deleted")
        history.push("/documents")
      })
      .catch(() => {
        props.flashMessage("Error occurred")
      })
  }

  const logClick = () => axios.post(`${process.env.REACT_APP_BACKEND_URL}/documents/${documentID}/click`).catch(e => console.warn(e))

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <Loading size='large' />
      </div>
    )
  }

  if (!document) {
    console.error("Couldn't retrieve document")
    return <ErrorPage title='Not found' description="Document couldn't be retrieved" />
  }

  const uploader = document.uploadedBy ?? {}
  const createdAt = new Date(document.createdAt).toDateString()
  const updatedAt = new Date(document.updatedAt).toDateString()
  let expiresInDays
  if (document.expiresAt) {
    expiresInDays = Math.floor((new Date(document.expiresAt) - new Date()) / (1000 * 3600 * 24))
  }
  
  const renderExpiration = () => {
    if (expiresInDays > 0) {
      return <div>{expiresInDays} day(s)</div>
    } else if (expiresInDays === 0) {
      return <div>Today</div>
    } else {
      return <div>Expired</div>
    }
  }

  return (
    <Page {...controls} active={true}>
      <Page.Top back onMenuOpen={openMenu} onBack={() => history.push("/documents")}>
        Document
      </Page.Top>

      {/* Meta Data */}
      <Page.Section>
        <div className={c.content}>
          <h2>{document.title}</h2>
          <div>{document.description}</div>
          <div>
            <div className={c.title}>Uploaded by</div>
            <IconItem text={`${uploader?.firstName} ${uploader?.lastName}`} icon={uploader?.profileImageURL} />
            <div className={c.date}>
              <img src={calendarIcon} className={c.icon}  alt=''/>
              {createdAt}
            </div>
          </div>
          <div>
            <div className={c.title}>Last updated</div>
            <div className={c.date}>
              <img src={calendarIcon} className={c.icon}  alt=''/>
              {updatedAt}
            </div>
          </div>
          {document.expiresAt ? (
            <div>
              <div className={c.title}>Expires in</div>
              <div className={c.date}>
                <img src={calendarIcon} className={c.icon} alt=''/>
                {renderExpiration()}
              </div>
            </div>
          ) : (
            <p>No expiration</p>
          )}
        </div>
      </Page.Section>

      {/* Document */}
      <div>
        <ListItem>Linked Document</ListItem>
        <ListItem bordered>
          <a href={document.signedUrl} target='_blank' onClick={logClick} rel="noreferrer">
            <Document>{document.name}</Document>
          </a>
        </ListItem>
      </div>

      {/* Logs */}
      <div className='mt-4 pb-4'>
        <ListItem>Document History</ListItem>
        {logs.map(log => (
          <ListItem bordered className='d-flex align-items-center justify-content-between' key={log.id}>
            <div className='d-flex align-items-center flex-gap-1'>
              <IconItem text={new Date(log.timestamp).toDateString()} icon={calendarIcon} />
              <IconItem text={`${log.user?.firstName} ${log.user?.lastName}`} icon={log.user.profileImageURL} />
            </div>
            <div>{actionImage(log.action)}</div>
          </ListItem>
        ))}
      </div>

      {/* Buttons */}
      <Page.Section className='pb-4 d-flex justify-content-end'>
        <Button variant='warning' size='small' onClick={deleteDocument}>
          Delete
        </Button>
      </Page.Section>
    </Page>
  )
}

const actionImage = action => {
  let icon
  if (action === 'CREATED') {
    icon = uploadIcon
  } else if (action === 'MODIFIED') {
    icon = modifyIcon
  } else if (action === 'DOWNLOADED') {
    icon = downloadIcon
  } else {
    icon = undefined
  }
  return icon && <img src={icon} className={c.actionIcon} alt=''/>
}

const mapDispatchToProps = dispatch => ({
  flashMessage: message => dispatch(systemActions.flashMessage(message)),
})


export default connect(undefined, mapDispatchToProps)(DocumentDetails)
