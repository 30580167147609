import React, { useState, useEffect } from "react"
import Page, { useMenu } from "../components/Page"
import DocumentListItem from "../components/DocumentListItem"
import RightMenu from "../components/RightMenu"
import { useHistory } from "react-router-dom"
import Loading from "aius-comp/Loading"
import Button from "aius-comp/Button"
import filterIcon from '../images/icons/filter.svg'
import Select from "aius-comp/Select"
import IconItem from "../components/IconItem"
import axios from "axios"
import c from "./DocumentLibrary.module.scss"
import { connect } from "react-redux"

const DocumentLibrary = ({ can }) => {
  const [openMenu, controls] = useMenu()
  const [filterOpen, setFilterOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [documents, setDocuments] = useState([])
  const history = useHistory()

  // Options for query parameters
  const [hotels, setHotels] = useState([])
  const [uploaders, setUploaders] = useState([])
  const [categories, setCategories] = useState([])

  // Query parameters
  const [sort, setSort] = useState("asc")
  const [hotel, setHotel] = useState(undefined)
  const [uploader, setUploader] = useState(undefined)
  const [group, setGroup] = useState(undefined)
  const [category, setCategory] = useState(undefined)

  // Get documents
  useEffect(() => {
    const data = {
      params: {
        sort,
        hotel,
        uploader,
        group,
        category,
      },
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/documents`, data)
      .then(res => {
        const documents = res.data?.documents ?? []
        setDocuments(documents)
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [sort, hotel, uploader, group, category])

  // Get user's hotels
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/locations`)
      .then(res => {
        const hotels = res.data?.locations ?? []
        setHotels(hotels)
      })
      .catch(e => console.error(e))
  }, [])

  // Get users
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/locations/users`)
      .then(res => {
        const users = res.data?.users ?? []
        setUploaders(users)
      })
      .catch(e => console.error(e))
  }, [])

  // Get document categories
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/documents/categories`)
      .then(res => {
        const categories = res.data?.categories ?? []
        setCategories(categories)
      })
      .catch(e => console.error(e))
  }, [])

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <Loading size='large' />
      </div>
    )
  }

  const defaultOption = { value: undefined, label: "All" }

  const userOptions = [
    defaultOption,
    ...(uploaders?.map(user => ({
      value: user.id,
      label: <IconItem text={`${user.firstName} ${user.lastName}`} icon={user.profileImageURL} />,
    })) ?? []),
  ]

  const hotelOptions = [
    defaultOption,
    ...(hotels?.map(hotel => ({
      value: hotel.id,
      label: <IconItem text={hotel.name} icon={hotel.coverImageURL} />,
    })) ?? []),
  ]

  const categoriesByGroup = categories?.reduce((acc, category) => {
    const { group, id, name } = category
    const { name: groupName, id: groupId } = group
    if (!acc[groupId]) {
      acc[groupId] = {
        id: groupId,
        label: groupName,
        options: [],
      }
    }
    acc[groupId].options.push({ value: id, label: name })
    return acc
  }, {})

  const groupOptions = [defaultOption, ...Object.values(categoriesByGroup)?.map(group => ({
    value: group.id,
    label: group.label
  })) ?? []]

  const categoryOptions = [defaultOption, ...categoriesByGroup[group]?.options ?? []]

  return (
    <Page {...controls} active={true}>
      <Page.Top onMenuOpen={openMenu}>Document Library</Page.Top>

      {/* Controls */}
      <div className={c.controls}>
        <div>
          <img src={filterIcon} onClick={() => setFilterOpen(true)} alt=''/>
        </div>
        {can('documents:upload') && (
          <Button size='small' variant='grey' onClick={() => history.push("/documents/new")}>
            Add
          </Button>
        )}
      </div>

      {/* Document List */}
      <div className={c.list}>
        {documents?.length > 0 ? (
          documents.map(doc => (
            <DocumentListItem
              onClick={() => history.push(`/documents/${doc.id}`)}
              key={doc.id}
              title={doc.title}
              user={doc.uploadedBy}
              createdAt={doc.createdAt}
              expiresAt={doc.expiresAt}
            />
          ))
        ) : (
          <div className='d-flex pt-4 justify-content-center w-100'>No documents</div>
        )}

        {/* Filter Menu */}
        <RightMenu isOpen={filterOpen} close={() => setFilterOpen(false)}>
          <RightMenu.Section>
            <div className='d-flex flex-column flex-gap-2'>
              <Select
                label='Sort By'
                options={[
                  {
                    value: "desc",
                    label: "Descending",
                  },
                  {
                    value: "asc",
                    label: "Ascending",
                  },
                ]}
                onChange={option => setSort(option.value)}
                isSearchable={false}
                defaultValue={{
                  value: "desc",
                  label: "Descending",
                }}
              />
              <Select
                label='Uploaded By'
                options={userOptions}
                onChange={option => setUploader(option.value)}
                isSearchable={false}
                defaultValue={defaultOption}
              />
              <Select
                label='For Hotel'
                options={hotelOptions}
                onChange={option => setHotel(option.value)}
                isSearchable={false}
                defaultValue={defaultOption}
              />
              <Select
                label='Group'
                options={groupOptions}
                onChange={option => setGroup(option.value)}
                isSearchable={false}
                defaultValue={defaultOption}
              />
              <Select
                label='Category'
                options={categoryOptions}
                onChange={option => setCategory(option.value)}
                isSearchable={false}
                defaultValue={defaultOption}
              />
            </div>
          </RightMenu.Section>
        </RightMenu>
      </div>
    </Page>
  )
}

const mapStateToProps = state => ({
  can: action => state.user?.permissions?.includes(action)
})

export default connect(mapStateToProps)(DocumentLibrary)
