import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import CloseHeader from "../components/CloseHeader";
import Page from "../components/Page";
import { ChatFeed, Message } from "react-chat-ui";
import c from "./WorkflowMessage.module.scss";
import { Field, Form, Formik } from "formik";
import { useParams} from "react-router-dom"
import axios from "axios"
import {IconItemP} from "../components/IconItem"

const EachMassage = ({eachData}) => {
  const {user} = eachData;
  return (
    <>
      <IconItemP text={`${user.firstName} ${user.lastName}`} icon={user.profileImageURL} textClass='whiteText'/>
      <span>{eachData.text}</span><br />
      <span className={c.ago}>{eachData.ago}</span>
    </>
  )
}

const WorkflowMessage = (props) => {
    const { history } = props;
    const workflowRunId = useParams().id
    const title = useParams().title

    const [messages, setMessages] = useState([]);
    const isTyping = false;
    const [toggleTrigger, setToggleTrigger] = useState(false);


    useEffect(() => {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/workflow-run-comments/${workflowRunId}`)
        .then(res => {
          let messages = [];
          res.data.map((eachData) => {
            messages.push( new Message({
              id: eachData.chat_id,
              message: <EachMassage eachData={eachData} />,
            }) )
            return null;
          })

          setMessages(messages);
          scrollToBottom();
        })
        .catch(e => {
          history.goBack()
        })
    }, [toggleTrigger, history, workflowRunId])

    const messagesEndRef = useRef(null)
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    return (
        <Page dark>
            <Page.Section className={c.fixedHeader}>
                <div>
                  {/* Close header */}
                  <CloseHeader
                      title={title}
                      onClose={() => history.goBack()}
                  />
                </div>
            </Page.Section>
            <div className={c.chatContainer}>
                <div className={c.chatHistory}>
                <ChatFeed
                    messages={messages} // Array: list of message objects
                    isTyping={isTyping} // Boolean: is the recipient typing
                    hasInputField={false} // Boolean: use our input, or use your own
                    showSenderName // show the name of the user who sent the message
                    bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
                    // JSON: Custom bubble styles
                    bubbleStyles={{
                        text: {
                            fontSize: 14,
                        },
                        chatbubble: {
                            borderRadius: 10,
                            padding: 10,
                            backgroundColor : '#6f6f6f'
                        },
                        userBubble: {
                          backgroundColor : '#186a69'
                        }
                    }}
                />
                </div>

                <Formik
                    initialValues={{
                      text: "",
                    }}
                    validate={values => {
                      const errors = {};
                      if (!values.text) {
                        errors.text = 'Type a message before send';
                      } 
                      return errors; //props.errors.text && props.touched.text && props.errors.text
                    }}
                    onSubmit={(values, actions) => {
                      
                      const data = {
                        workflow_run_id: workflowRunId,
                        ...values,
                      }
                      axios.post(`${process.env.REACT_APP_BACKEND_URL}/workflow-run-comment`, data)
                        .then(res => {
                          //props.flashMessage('Document created')
                          //history.push('/documents')
                          setToggleTrigger(!toggleTrigger);
                          actions.resetForm()
                        })
                        .catch(e => {
                          actions.setSubmitting(false);
                          console.error(e)
                        })
                    }}
                >
                    {(props) => (
                        <div className={c.sendMsgContainer}>
                          <Form>
                            <div className={c.fields} >
                              <Field className={c.fieldInput} name="text" placeholder="Type you comment.." autoComplete={'off'} />
                              <button className={c.fieldButton} type="submit">Send</button>
                            </div>
                          </Form>
                        </div>
                    )}
                </Formik>
                <div ref={messagesEndRef} />
            </div>
        </Page>
    );
};

export default connect(null)(WorkflowMessage);
