import Button from 'aius-comp/Button'
import React from 'react'
import { connect } from 'react-redux'
import Page, { useMenu } from "../components/Page"
import c from './UserProfile.module.scss'
import { RiArrowLeftCircleLine, RiDeleteBinLine } from "react-icons/ri";
import { useHistory, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import { systemActions } from "../store/actions"



const ProfileMessageDetails = ({ fetchMsgCount , location }) => {
  const [openMenu, controls] = useMenu()
  const history = useHistory()
  const [message, setMessage] = useState([]);
  const { id } = useParams()
  const { state } = location
  const { box } = state

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/profile-message/${id}?box=${box}`).then( res => {
      fetchMsgCount();
      setMessage(res.data.data);
    })// eslint-disable-next-line
  }, [id, box]);

  const handleDelete = () => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/profile-message/${id}`).then( res => {
      history.goBack()
    })
  }


  return (
    <Page {...controls}>
      <Page.Top back={true} onBack={() => {history.goBack()}} onMenuOpen={openMenu}>{message.title}</Page.Top>
      <Page.Section>
        <div className='d-flex flex-gap-2 flex-column align-items-center mt-4 mb-4'>
          <img className={c.icon} src={message?.from?.profileImageURL} alt=''/>
          <div className='d-flex flex-column align-items-center'>
            <div className='display-4'>
              {message?.from?.firstName} {message?.from?.lastName}
            </div>
            <div className='small'>{message?.created_at}</div>
          </div>
        </div>
      </Page.Section>

      <Page.Section className='mt-5 mb-5'>
        <dl>
          <dt>{message?.severity}</dt>
          <dd>{message?.text}</dd>
        </dl>
      </Page.Section>


      <Page.Section>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <Button size='small' variant='grey' onClick={() => history.goBack()}> <RiArrowLeftCircleLine /> Back </Button>
            <Button size='small' variant='warning' onClick={handleDelete}> <RiDeleteBinLine /> Delete </Button>
          </div>
      </Page.Section>
      
    </Page>
  )
}

const mapDispatchToProps = dispatch => ({
  fetchMsgCount: () =>  dispatch(systemActions.fetchMsgCount())
})

export default connect(null, mapDispatchToProps)(ProfileMessageDetails)
