import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import c from "./Toggle.module.scss"
import cn from "classnames"

/**
*   `import Toggle from "aius-comp/Toggle"` 
**/

const Toggle = props => {
  const [toggled, setToggled] = useState(props.value)

  useEffect(() => {
    if (props.disabled) {
      setToggled(false)
    }
  }, [props.disabled])

  const containerClasses = cn([
    c.container, 
    {
      [c.wide]: props.wide, 
      [c.reverse]: props.reverse,
    }

  ])
  const toggleClasses = cn(
    c.toggle, 
    {
      [c.disabled]: props.disabled,
    }
  )
  const buttonClasses = cn(
    c.button, 
    {
      [c.toggled]: toggled,
      [c.disabledButton]: props.disabled
    }
  )

  const handleClick = () => {
    const callback = props.onChange || props.onClick
    if (callback) {
      callback(!toggled)
    }
    setToggled(!toggled)
  }

  return (
    <div className={containerClasses}>
      <div className={toggleClasses} onClick={() => !props.disabled ? handleClick() : null}>
        <div className={buttonClasses}></div>
      </div>  
      <span className={c.children}>{props.children}</span>
    </div>
  )
}

export const LabelToggle = ({ label, error, ...rest }) => {
  return (
    <div>
      <div className={c.label}>
        {label}
        <span className={c.error}>{error}</span>
      </div>
      <Toggle {...rest} />
    </div>
  )
}

Toggle.defaultProps = {
  value: false,
  reverse: false,
  wide: false,
  disabled: false,
  onChange: toggled => console.log('[Toggle] Default callback, none provided. Value: ', toggled)
}

Toggle.propTypes = {
  value: PropTypes.bool,
  reverse: PropTypes.bool,
  wide: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Toggle