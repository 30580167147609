import React, { useState, useEffect } from "react"
import Input from "aius-comp/Input"
import InputBox from "aius-comp/InputBox"
import Select from "aius-comp/Select"
import Dropzone from "aius-comp/Dropzone"
import { Field, useField } from "formik"
import c from './RenderElement.module.scss'

export const RenderElement = ({ type, ...params }) => {
  switch (type) {
    case "NUMBER":
      return <WorkflowText element={type} {...params} type='number' />
    case "TEXT":
      return <WorkflowText element={type} {...params} />
    case "TEXTAREA":
      return <WorkflowTextArea element={type} {...params} />
    case "DATE":
      return <WorkflowDate element={type} {...params} type='date' />
    case "DATETIME":
      return <WorkflowDate element={type} {...params} type='datetime-local' />
    case "SELECT": //@TODO
      return <WorkflowSelect element={type} {...params} />
    case "MULTISELECT":
      return <WorkflowSelect element={type} {...params} />
    case "FILE":
      return <WorkflowDropzone element={type} {...params} />
    case "VIDEO":
      return <WorkflowVideo element={type} {...params} />
    case "YOUTUBE":
      return <WorkflowYouTube element={type} {...params} />
    case "INFO":
      return <WorkflowInfo element={type} {...params} />
    default:
      console.warn("[RenderElement] Unknown type passed: ", type)
      return null
  }
}

const WorkflowText = props => {
  const { id, element, fieldName, name, default: _default, stepId, placeholder, description } = props
  // eslint-disable-next-line no-unused-vars
  const [field, _, helpers] = useField(fieldName)
  useEffect(() => {
    if (_default) {
      helpers.setValue({
        value: _default.value,
        elementType: element,
        elementId: id,
        stepId: stepId,
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Field
        label={name}
        name={field.name}
        placeholder={placeholder}
        value={field.value?.value ?? ""}
        onChange={e =>
          helpers.setValue({
            value: e.target.value,
            elementType: element,
            elementId: id,
            stepId: stepId,
          })
        }
        as={Input}
      />
      <p className='mt-2' style={{whiteSpace: "pre-wrap"}}>{description}</p>
    </div>
  )
}

const WorkflowTextArea = props => {
  const { id, element, fieldName, name, default: _default, stepId, placeholder, description } = props
  // eslint-disable-next-line no-unused-vars
  const [field, _, helpers] = useField(fieldName)

  useEffect(() => {
    if (_default) {
      helpers.setValue({
        value: _default.value,
        elementType: element,
        elementId: id,
        stepId: stepId,
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      <Field
        label={name}
        name={field.name}
        default={_default}
        placeholder={placeholder}
        value={field.value?.value ?? ""}
        onChange={e =>
          helpers.setValue({
            value: e.target.value,
            elementType: element,
            elementId: id,
            stepId: stepId,
          })
        }
        as={InputBox}
      />
      <p className='mt-2' style={{whiteSpace: "pre-wrap"}}>{description}</p>
    </div>
  )
}

const WorkflowDate = props => {
  const { id, element, fieldName, name, default: _default, stepId, placeholder, type, description } = props
  // eslint-disable-next-line no-unused-vars
  const [field, _, helpers] = useField(fieldName)
  return (
    <div>
      <Field
        label={name}
        name={field.name}
        placeholder={placeholder}
        type={type}
        default={_default}
        value={field.value?.value ?? ""}
        onChange={e =>
          helpers.setValue({
            value: e.target.value,
            elementType: element,
            elementId: id,
            stepId: stepId,
          })
        }
        as={Input}
      />
      <p className='mt-2' style={{whiteSpace: "pre-wrap"}}>{description}</p>
    </div>
  )
}

const WorkflowDropzone = props => {
  const { id, element, fieldName, name, stepId, multiple = "false", description } = props
  // eslint-disable-next-line no-unused-vars
  const [field, _, helpers] = useField(fieldName)
  return (
    <div className='d-flex flex-column'>
      <label>{name}</label>
      <Dropzone
        name={field.name}
        multiple={multiple}
        onChange={files => {
          files = files.map(file => ({
            value: file,
            elementType: element,
            elementId: id,
            stepId: stepId,
          }))
          helpers.setValue(files)
        }}
        onRemoveAll={ value => {
          helpers.setValue(value)
        }}
      />
      <p className='mt-2' style={{whiteSpace: "pre-wrap"}}>{description}</p>
    </div>
  )
}

const WorkflowVideo = props => {
  const { name, placeholder, description } = props
  return (
    <div>
      <h3>{name}</h3>
      <p className='mt-2' style={{whiteSpace: "pre-wrap"}}>{description}</p>
      <video 
          className={c.video}
          controls 
          autoPlay
          src={placeholder} />
    </div>
  )
}

const WorkflowYouTube = props => {
  const { name, placeholder, description } = props
  return (
    <div>
      <h3>{name}</h3>
      <p className='mt-2' style={{whiteSpace: "pre-wrap"}}>{description}</p>
      <div className={c.videoContainer}>
        <iframe width="560" height="315" src={placeholder} title={name} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  )
}

const WorkflowInfo = props => {
  const { name, description } = props
  return (
    <div>
      <h3>{name}</h3>
      <p className='mt-2' style={{whiteSpace: "pre-wrap"}}>{description}</p>
    </div>
  )
}

export const WorkflowSelect = props => {
  const [usedDefaults, setDefaultsAsUsed] = useState(new Set())
  const { id, element, fieldName, name, placeholder, default: _default, stepId, options, addScore, description } = props
  // eslint-disable-next-line no-unused-vars
  const [field, _, helpers] = useField(fieldName)
  const defaultValue = options?.find(option => option.selected === true)?.value ?? undefined
  useEffect(() => {
    if (_default && !usedDefaults.has(id)) {
      let option = options?.find(option => option.id === _default.optionId) ?? undefined

      //@TODO : Need to change if to value are same
      if(option === undefined){
        option = options?.find(eachOption => {
          return eachOption.value === _default.value
        }) ?? undefined
      }

      if (option) {
        console.log("[WorkflowSelect] Default found: ", _default)
        setDefaultsAsUsed(new Set([...usedDefaults, id]))
        setOption(option, _default.option?.weight ?? 0)
      } else {
        console.warn("[WorkflowSelect] Default provided, but no option found...")
      }
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const setOption = (option, weight) => {
    console.log("[WorkflowSelect] Changing option: ", option)
    
    // Add score callback
    addScore?.(id, weight)
    
    // Set value on Formik
    helpers.setValue({
      value: option.value,
      optionId: option.id,
      elementType: element,
      elementId: id,
      stepId: stepId,
    })
  }

  const padding = "mt-5"

  return (
    <div>
      <Select
        label={name}
        name={field.name}
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder}
        value={options?.find(option => option.value === field.value?.value) ?? ""}
        onChange={option => setOption(option, option.weight)}
      />
      <p className={padding} style={{whiteSpace: "pre-wrap"}}>{description}</p>
    </div>
  )
}


export default RenderElement
