import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
//import { connect } from "react-redux"
//import { workflowActions } from "../store/actions"
import Button from "aius-comp/Button"
import FileInfo from "aius-comp/FileInfo"
import CloseHeader from "../components/CloseHeader"
import sanitizeHtml from "sanitize-html"
import c from "./ReportBegin.module.scss"
import Page from "../components/Page"
import axios from "axios"

/*  
  Start incident/intelligence report run.
  Displays description and documents of the report type.
*/

const TaskBegin = ({ props }) => {
  const history = useHistory()

  // Get workflow ID as URL parameter
  const { id: workflowID } = useParams()

  const [workflow, setWorkflow] = useState()

  
  // Call fetch workflows action creator, if workflows haven't been fetched yet
  useEffect(() => {
    // Get workflow from state by ID
    /*
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/workflows/${workflowID}/full`)
    .then(res => {
      setWorkflow(res.data.workflow)
    })
    .catch(e => {
      console.error(e)
    })
    */

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${workflowID}/full`)
        .then(res => setWorkflow(res.data?.workflowRun))
        .catch(err => console.error(err))
        //.finally(() => setLoading(false))

  }, [workflowID]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!workflow) {
    return <h2>Workflow not found</h2>
  }

  const { name, documents, description: dangerousDescriptionHTML } = workflow.workflow

  // Sanitise description HTML
  const sanitizedHTML = sanitizeHtml(dangerousDescriptionHTML)
  const description = <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>

  return (
    <Page>
      <Page.Section>
        <div >
          <CloseHeader title={name} onClose={() => history.goBack()} />
          <div className={c.content}>
            <div className={c.description}>{description}</div>
            {Array.isArray(documents) &&
              documents.map(doc => (
                <FileInfo
                  key={doc.url}
                  className='mt-2'
                  fileName={doc?.title}
                  fileSize={doc?.size}
                  mimeType={doc?.mimeType}
                  url={doc?.url}
                  info={doc?.info}
                />
              ))}
          </div>

          {/* Bottom */}
          <div className={c.bottom}>
            <Button onClick={() => history.push({
              pathname: `/task/${workflow.id}/run`,
              state: { run: workflow}
            })}>Start</Button>
          </div>
        </div>
      </Page.Section>
    </Page>
  )
}

/*
const mapStateToProps = state => ({
  workflows: state.workflows.tasks,
  fetched: state.workflows.fetched,
})

const mapDispatchToProps = dispatch => ({
  fetchWorkflows: () => dispatch(workflowActions.fetchWorkflows()),
})
*/

export default TaskBegin //connect(mapStateToProps, mapDispatchToProps)(
