import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { connect } from 'react-redux'
import Page from '../components/Page'
import ListItem from "../components/ListItem"
import IncidentBreakdown from "../components/IncidentBreakdown"
import IncidentBarChart from "../components/IncidentBarChart"
import alertIcon from '../images/icons/alert-circle.svg'
import closeIcon from '../images/icons/close-icon-white.svg'
import RiskItem from "../components/RiskItem"
import c from './HotelAnalytics.module.scss'
import Button from 'aius-comp/Button'
import ControlSelect from '../components/ControlSelect'
import axios from 'axios'
import RiskProfileChart from '../components/RiskProfileChart'
import HotelProfileScoreBreakdown from "../components/HotelProfileScoreBreakdown"
import NotificationIcon from '../components/NotificationIcon'
import { useLocation } from 'react-router-dom';
import { getRiskPercentage } from "aius-fn/riskCalculator"

const MAX_RISK = 75;
const HotelAnalytics = props => {
  const location = useLocation();
  const history = useHistory()
  const [days, setDays] = useState(7)
  const [statistics, setStatistics] = useState({})
  const [alerts, setAlerts] = useState({})
  const [risks, setRisks] = useState({})
  const { id = 1 } = useParams()

  // Get selected hotel
  const hotel = history.location.state?.hotel ?? props.hotels.find(_hotel => _hotel.id === id)

  // Get hotel's notificaitons
  const notifications = props.notifications.filter(alert => alert.locations.some(_hotel => _hotel.id === hotel.id))

  // Get daily incident statistics
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/incidents/daily`, { params: { days, reverse: true } })
      .then(res => setStatistics(res.data.statistics))
      .catch(e => console.warn(e))
  }, [days])

  // Get alerts
  useEffect(() => {
    if(hotel){
      axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/alerts`, { params: { hotel: hotel.id } })
      .then(res => setAlerts(res.data.alerts))
      .catch(e => console.warn(e))
    }
  }, [hotel])

  const sum = array => (Array.isArray(array) ? array.reduce((a, b) => a + b, 0) : 0)

  // Get risks
  useEffect(() => {
    if(hotel){
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/locations/risks/${hotel?.id}`)
        .then(res => setRisks(res.data.content)) //setRisk(res.data)
        .catch(e => console.warn(e))// eslint-disable-next-line
    }
  }, [hotel])

  return (
    <Page>
      {/* Top Cover */}
      <div className={c.cover} style={{ backgroundImage: `url(${hotel?.coverImageURL})` }}>
        <div className={c.overlay}>
          {/* Top */}
          <div className={c.top}>
            <div
              className={c.detailsIcon}
              onClick={() => history.push(`/hotels/${hotel?.id}`, { backPath: location.pathname })}
            >
              <img src={alertIcon} alt=''/>
            </div>
            <img className={c.closeIcon} src={closeIcon} onClick={() => history.push("/")} alt=''/>
          </div>
          {/* Bottom */}
          <div>{hotel?.name}</div>
        </div>
      </div>

      {/* Controls */}
      <ListItem>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <h3>Incidents</h3>
          <div className='d-flex flex-gap-2'>
            <ControlSelect onChange={e => setDays(e.target.value)} defaultValue='7'>
              <option value='7'>Last 7 days</option>
              <option value='14'>Last 14 days</option>
              <option value='30'>Last 30 days</option>
              <option value='90'>Last 3 months</option>
              <option value='365'>Last 12 months</option>
              <option value=''>All</option>
            </ControlSelect>
          </div>
        </div>
      </ListItem>

      <div className={c.resChangeFlex}>
        {/* Stacked Bar Chart */}
        <Page.Section className={c.barchart}>
          <div className='mt-5 mb-5 w-100'>
            <IncidentBarChart
              minor={statistics?.minor}
              major={statistics?.major}
              severe={statistics?.severe}
              crisis={statistics?.crisis}
            />
          </div>
        </Page.Section>

        {/* Incident Breakdown */}
        <Page.Section className={c.barchart}>
        <div className='d-flex justify-content-center w-100 pb-5'>
          <IncidentBreakdown
            minor={sum(statistics?.minor)}
            major={sum(statistics?.major)}
            severe={sum(statistics?.severe)}
            crisis={sum(statistics?.crisis)}
          />
        </div>
          <div className='d-flex flex-row-reverse'>
          <Button onClick={() => history.push("/reports")}>Reports</Button>
        </div>
      </Page.Section>
      </div>
      {/* Button | Reports */}
      <Page.Section>

      </Page.Section>


      <div className={c.alerts}>
        <Page.Section>
          <h3>AIUS PROTECT RATING&trade;</h3>
          <div className={c.imageContainer}>
            <RiskProfileChart
              value={getRiskPercentage(risks?.risk_rating_value, MAX_RISK)}
              color={risks?.risk_rating?.color}
            />
            <p className={c.label}>{risks?.risk_rating_value}</p>
            <p className={c.description}><strong>{risks?.risk_rating?.name}</strong><br/>This hotel currently has a {risks?.risk_rating?.name} Risk Rating</p>
          </div>

          <div className='d-flex justify-content-center w-100 pb-5'>
            <HotelProfileScoreBreakdown
              catastrophe={risks?.catastrophe_risk_score?.score}
              political={risks?.political_risk_score?.score}
              profile={risks?.risk_factor?.score}
              social={risks?.social_risk_score?.score}
              colors={[
                risks?.catastrophe_risk_score?.color,
                risks?.political_risk_score?.color,
                risks?.risk_factor?.color,
                risks?.social_risk_score?.color
              ]}
            />
          </div>
          <div className='mt-4 mb-4 d-flex flex-row-reverse'>
            <Button onClick={() => history.push(`/risks/${hotel.id}`, { backPath: location.pathname })}>Details</Button>
          </div>
        </Page.Section>
      </div>



      {/* Alerts */}

        <Page.Section>
          <h3>Alerts</h3>
        </Page.Section>


        {Array.isArray(alerts) && alerts.length > 0 ? (
          alerts.map(alert => (
            <div className={c.alertItem} key={alert.id}>
              <NotificationIcon icon={alert.icon} className={c.icon} />
              <div className={c.content}>
                <div className={c.title}>{alert.name}</div>
                <div className={c.subtitle}>{alert.description}</div>
              </div>
            </div>
          ))
        ) : (
          <div className='d-flex justify-content-center pt-4 pb-4'>
            <span>No alerts</span>
          </div>
        )}

      {/* Notifications */}

        <Page.Section>
          <h3>Notifications</h3>
        </Page.Section>

        {Array.isArray(notifications) && notifications.length > 0 ? (
          notifications.map(notification => (
            <RiskItem
              key={notification.id}
              type={notification.category}
              severity={notification.severity}
              title={notification.title}
              subtitle={notification.message}
              icon={notification.icon}
            >
              {notification?.action?.id && (
                <div className='d-flex justify-content-end'>
                  {notification.actionRun === undefined ? (
                    <Button
                      size='small'
                      variant='warning'
                      onClick={() => history.push(`/alert-action/${notification.action.id}`, { notificationID: notification.id, backPath: location.pathname })}
                    >
                      Take action
                    </Button>
                  ) : (
                    <Button disabled size='small' variant='grey'>
                      Action taken
                    </Button>
                  )}
                </div>
              )}
            </RiskItem>
          ))
        ) : (
          <div className='d-flex justify-content-center pt-4 pb-4'>
            <span>No notifications</span>
          </div>
        )}

    </Page>
  )
}

const mapStateToProps = state => ({
  notifications: state.system.notifications.filter(notification => notification.external).sort(sortBySeverity),
  hotels: state.hotels.list
})

const sortBySeverity = (a, b) => {
  const severities = { 'CRISIS': 0, 'SEVERE': 1, 'MAJOR': 2, 'MINOR': 3, }
  if (severities[a.severity] > severities[b.severity]) { return 1 }
  else if (severities[a.severity] < severities[b.severity]) { return -1 }
  else { return 0 }
}

export default connect(mapStateToProps)(HotelAnalytics)
