export const types = {
  flashMessage: "FLASH_MESSAGE",
  popMessage: "POP_MESSAGE",
  showNotifications: "SHOW_NOTIFICATIONS",
  hideNotifications: "HIDE_NOTIFICATIONS",
  setNotifications: "SET_NOTIFICATIONS",
  setMsgCount: "SET_MSG_COUNT",
  decreaseMsgCountByOne: "DECREASE_MSG_COUNT_BY_ONE",
  removeNotification: "REMOVE_NOTIFICATION",
  removeAllNotifications: "REMOVE_ALL_NOTIFICATIONS",
}

export default types
