import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import menuIcon from "../../../images/icons/menu.svg";
import Bell from "../../Bell";
import { systemActions } from "../../../store/actions";
import c from "../../Header.module.scss";

import Search from "aius-comp/Search";
import SearchResultList from "../../SearchResultList";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import axios from "axios";
import Can from "../../Can";
import { Link } from "react-router-dom";
import Envelope from "../../Envelope";

/*
  Header component used within Page wrapper.
  Shows menu icon, notification icon and profile picture.
*/

const TabHeader = ({
    profileImageURL,
    firstName,
    lastName,
    showNotifications,
    bellActive,
    notificationsOpen,
    msgCount,
    ...props
}) => {
    const history = useHistory();

    const [hubResult, setHubResult] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    // Show profile color based on first character of first name
    const profileColor =
        Math.max(0, Math.min(255, firstName.charCodeAt(0))) || 128;
    const whichColor = profileColor % 3;
    const baseColor = 36;
    const backgroundColor =
        whichColor === 0
            ? `rgb(${profileColor}, ${baseColor}, ${baseColor})`
            : whichColor === 1
            ? `rgb(${baseColor}, ${profileColor}, ${baseColor})`
            : `rgb(${baseColor}, ${baseColor}, ${profileColor})`;

    // Show user's initials
    const initials = <span>{firstName[0] + lastName[0]}</span>;

    const searchAPI = (query) =>
        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/locations/search?query=${query}`
        );
    const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 500);

    const handleSearch = async (e) => {
        const query = e.target.value;
        if (query !== "") {
            const result = await searchAPIDebounced(query);
            setHubResult(result.data);
            setDropdownVisible(true);
        } else {
            console.log("Cancelled");
            setDropdownVisible(false);
        }
    };

    return (
        <div className={c.tabHeader}>
            {/* Left Side */}
            
            <div className={c.allMenu}>
                <img
                    onClick={props.onMenuOpen}
                    className={c.menu}
                    src={menuIcon}
                    alt=""
                />
                <nav className={c.tabMenu}>
                    <ul>
                        <Can perform='documents-menu:view'>
                            <li><Link to='/documents'>Documents</Link></li>
                        </Can>
                        <Can perform='assessments-menu:view'>
                            <li><Link to='/assessments'>Assessments</Link></li>
                        </Can>
                        <Can perform='reports-menu:view'>
                            <li><Link to='/reports'>Reports</Link></li>
                        </Can>
                        <li><Link to='/training'>Training</Link></li>
                    </ul>
                </nav>
            </div>


            

            {/* Right side */}
            <div className="d-flex align-items-center flex-gap-2">
                <div className={c.searchContainerWidth}>
                    <Search onChange={handleSearch} />
                    <SearchResultList
                        hubResult={hubResult}
                        dropdownVisible={dropdownVisible}
                    />
                </div>
                <Envelope count={msgCount} active={msgCount > 0} onClick={() => history.push('/mail/inbox')}/>
                <Bell
                    active={bellActive}
                    onClick={() => !notificationsOpen && showNotifications()}
                />
                <div
                    onClick={() => history.push("/profile")}
                    className={c.avatar}
                    style={
                        profileImageURL
                            ? null
                            : { backgroundColor: backgroundColor }
                    }
                >
                    {profileImageURL ? (
                        <img className={c.image} src={profileImageURL} alt="" />
                    ) : (
                        initials
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    profileImageURL: state.user.profileImageURL,
    bellActive: state.system?.notifications?.length > 0,
    notificationsOpen: state.system.notificationsOpen,
    msgCount: state.system.msgCount
});

const mapDispatchToProps = (dispatch) => ({
    showNotifications: () => dispatch(systemActions.showNotifications()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabHeader);

/**
 * <Can perform='hotels-menu:view'>
        <li>My Hotels</li>
    </Can>
 */
