import React from 'react'
import dateIcon from '../images/icons/calendar.svg'
import IconItem from './IconItem'
import cn from 'classnames'
import c from './ReportListItem.module.scss'

const ReportListItem = ({
  category,
  type,
  title,
  subtitle,
  date,
  severity,
  status,
  iconURL,
  iconText,
  className,
  is_seen = 1, 
  ...rest
}) => {

  const severities = {
    "crisis": c.crisis,
    "severe": c.severe,
    "major": c.major,
    "minor": c.minor,
    "none": c.none,
  }

  const displayIconItem = iconURL && iconText

  const barClasses = cn(c.bar, severities[severity])

  return (
    <div className={cn(c.item, is_seen ? c.seen : c.unseen)} {...rest}>
      <div className={barClasses}></div>
      <div className={c.content}>
        {(category && type) && (
          <div className={c.category}>
            <div className={c.muted}>{category} /</div>
            <div>{type}</div>
          </div>
        )}
        <div className={c.title}>{title}</div>
        {subtitle && <div className={c.subtitle}>{subtitle}</div>}
        <div className={c.bottom}>
          {displayIconItem && <IconItem icon={iconURL} text={iconText} /> }
          <div className={c.date}>
            <img src={dateIcon} className={c.img} alt='' />
            <div>{date}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportListItem
