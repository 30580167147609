import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import Page, { useMenu } from '../components/Page'
import Select from "aius-comp/Select"
import { connect } from "react-redux"
import { LabelToggle } from "aius-comp/Toggle"
import { Field, Formik } from "formik"
import Input from "aius-comp/Input"
import InputBox from "aius-comp/InputBox"
import Document from "../components/Document"
import ListItem from "../components/ListItem"
import SelectIcon from "../components/SelectIcon"
import Dropzone from 'aius-comp/Dropzone'
import Button from 'aius-comp/Button'
import Badge from 'aius-comp/Badge'
import axios from 'axios'
import { readFile } from 'aius-fn/readFile'
import { systemActions } from '../store/actions'
import * as yup from "yup"

const NewDocument = props => {
  const [openMenu, controls] = useMenu()
  const [hotels, setHotels] = useState([])
  const [categories, setCategories] = useState([])
  const [loadingHotels, setLoadingHotels] = useState(true)
  const [loadingCategories, setLoadingCategories] = useState(true)
  const history = useHistory()

  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const pad = num => (num < 10 ? `0${num}` : `${num}`)
  const today = `${year}-${pad(month)}-${pad(day)}`

  // Get user's hotels
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/locations`)
      .then(res => {
        const hotels = res.data?.locations ?? []
        setHotels(hotels)
      })
      .catch(e => console.error(e))
      .finally(() => setLoadingHotels(false))
  }, [])

  // Get document categories
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/documents/categories`)
      .then(res => {
        const categories = res.data?.categories ?? []
        setCategories(categories)
      })
      .catch(e => console.error(e))
      .finally(() => setLoadingCategories(false))
  }, [])

  // Submit document
  const submitDocument = async (values, { setSubmitting }) => {
    setSubmitting(true)
    const { file: rawFile, category, hotels, ...rest } = values
    const file = await readFile(rawFile)
    const data = {
      category: category.value,
      hotels: hotels.map(({ value }) => value),
      file: file,
      ...rest,
    }

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/documents`, data)
      .then(res => {
        props.flashMessage('Document created')
        history.push('/documents')
      })
      .catch(e => {
        setSubmitting(false)
        console.error(e)
      })
  }

  // Aggregate by group
  const groupedCategoriesObject = categories.reduce((acc, category) => {
    const { group, id, name } = category
    const { name: groupName } = group
    if (!acc[groupName]) {
      acc[groupName] = {
        label: groupName,
        options: [],
      }
    }
    acc[groupName].options.push({ value: id, label: name })
    return acc
  }, {})
  const groupedCategories = Object.values(groupedCategoriesObject)

  const options = hotels.map(hotel => ({ value: hotel.id, label: <SelectIcon label={hotel.name} url={hotel.coverImageURL} /> }))

  return (
    <Page {...controls} active={true}>
      <Page.Top back onMenuOpen={openMenu} onBack={() => history.push("/documents")}>
        New Document
      </Page.Top>

      <Formik
        initialValues={{
          title: "",
          file: undefined,
          description: "",
          allHotels: true,
          hotels: [],
          category: null,
          private: false,
          expires: false,
          expiresIn: today,
        }}
        onSubmit={submitDocument}
        validationSchema={yup.object({
          title: yup.string().required("Enter document title"),
          file: yup
            .mixed()
            .test("exists", "Select a document", file => file !== undefined)
            .test("size", "Document is too large (>5MB)", file => file && file.size < 5000000),
          allHotels: yup.bool(),
          private: yup.bool(),
          expires: yup.bool(),
          category: yup.object().nullable().required("Select document category"),
          expiresIn: yup.date().when("expires", {
            is: true,
            then: yup.date().required("Select expiration date"),
          }),
          hotels: yup.array().when(["allHotels", "private"], {
            is: false,
            then: yup.array().min(1, "Select at least one hotel"),
          }),
        })}
      >
        {({ values, errors, setFieldValue, handleSubmit, validateField }) => (
          <div>
            <Page.Section>
              {/* Choose document */}
              <Dropzone
                className='mt-4 mb-4'
                onChange={files => {
                  setFieldValue("file", files?.[0] ?? undefined)
                }}
                onRemoveAll={ value => {
                  setFieldValue("file", undefined);
                }}
                selected={
                  values.file ? (
                    <ListItem>
                      <Document>{values.file.name}</Document>
                    </ListItem>
                  ) : undefined
                }
              />

              {/* For all hotels */}
              <LabelToggle
                label='All Hotels'
                onChange={boolean => {
                  setFieldValue("allHotels", boolean)
                  if (boolean) {
                    setFieldValue("hotels", [])
                    // Timeout solves weird edge case
                    setTimeout(() => validateField("hotels"), 1)
                  }
                }}
                value={values.allHotels}
                disabled={values.private}
              />

              {/* Individual hotel selection */}
              <Select
                label='Individual Hotels'
                options={options}
                isLoading={loadingHotels}
                isMulti
                onChange={options => setFieldValue("hotels", options)}
                value={values.hotels}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                isDisabled={values.private || values.allHotels}
              />

              {/* Document title */}
              <Field name='title' label='Title' as={Input} />
              
              {/* Document description */}
              <Field name='description' label='Description' as={InputBox} />

              {/* Category selection */}
              <Select
                label='Category'
                options={groupedCategories}
                isLoading={loadingCategories}
                onChange={options => {
                  setFieldValue("category", options)
                }}
                value={values.category}
              />

              {/* Is private */}
              <LabelToggle
                label='Private'
                value={values.private}
                onChange={bool => {
                  if (bool) {
                    setFieldValue("allHotels", false)
                    setFieldValue("hotels", [])
                    // Timeout solves weird edge case
                    setTimeout(() => validateField("hotels"), 1)
                  }
                  setFieldValue("private", bool)
                }}
              />

              {/* Does expire */}
              <LabelToggle label='Document Expires' value={values.expires} onChange={bool => setFieldValue("expires", bool)} />

              {/* When expires */}
              {values.expires && (
                <Input
                  label='Expiration Date'
                  type='date'
                  min={today}
                  value={values.expiresIn}
                  onChange={e => setFieldValue("expiresIn", e.target.value)}
                />
              )}

              <div className='pt-3 pb-3 d-flex flex-column flex-gap-1 align-items-start'>
                {/* Errors */}
                {Object.values(errors).map(error => (
                  <Badge variant='warning' pill key={error}>
                    {error}
                  </Badge>
                ))}
              </div>

              {/* Submit */}
              <Button wide onClick={handleSubmit}>
                Submit
              </Button>
            </Page.Section>
            <div className='pb-4'></div>
          </div>
        )}
      </Formik>
    </Page>
  )
}

const mapDispatchToProps = dispatch => ({
  flashMessage: message => dispatch(systemActions.flashMessage(message)),
})

export default connect(undefined, mapDispatchToProps)(NewDocument)
