import types from "../types/system"

export const system = (
  state = { 
    messages: [],
    notificationsOpen: false,
    notificationsFetched: false,
    notifications: [],
    msgCount: 0
  }, 
  { 
    type, 
    payload 
  }
  ) => {
  switch (type) {

    // Flash Messages
    case types.flashMessage: {
      const messages = [...state.messages, payload]
      return {
        ...state,
        messages,
      }
    }
    case types.popMessage: {
      const [...messages] = state.messages
      messages.pop();
      return {
        ...state,
        messages: messages ?? [],
      }
    }

    // Notifications & Alerts
    case types.showNotifications: {
      return {
        ...state,
        notificationsOpen: true,
      }
    }
    case types.hideNotifications: {
      return {
        ...state,
        notificationsOpen: false,
      }
    }
    case types.setNotifications: {
      return {
        ...state,
        notifications: payload,
        notificationsFetched: true,
      }
    }
    case types.removeNotification: {
      const notifications = state.notifications.filter(x => x.id !== payload)
      return {
        ...state,
        notifications,
      }
    }
    case types.removeAllNotifications: {
      return {
        ...state,
        notifications: [],
      }
    }
    case types.setMsgCount: {
      return {
        ...state,
        msgCount: payload,
      }
    }
    case types.decreaseMsgCountByOne: {
      let {msgCount} = state
      if(msgCount > 0){
        msgCount--;
      }
      return {
        ...state,
        msgCount: msgCount,
      }
    }
    default:
      return state
  }
}

export default system
