import { connect } from 'react-redux'
import Page, { useMenu } from "../components/Page"
import c from './UserProfile.module.scss'



const UserProfile = ({ profile }) => {
  const [openMenu, controls] = useMenu()

  return (
    <Page {...controls}>
      <Page.Top onMenuOpen={openMenu}>My Profile</Page.Top>
      <Page.Section>
        <div className='d-flex flex-gap-2 flex-column align-items-center mt-4 mb-4'>
          <img className={c.icon} src={profile.profileImageURL} alt=''/>
          <div className='d-flex flex-column align-items-center'>
            <div className='display-4'>
              {profile.firstName} {profile.lastName}
            </div>
            <div className='small'>{profile.role}</div>
          </div>
        </div>
      </Page.Section>

      <Page.Section className='mt-5 mb-5'>
        <dl>
          <dt>Phone number</dt>
          <dd>{profile.contactNumber}</dd>
          <dt>Email</dt>
          <dd>{profile.email}</dd>
        </dl>
      </Page.Section>

      <Page.Section className='mt-5 mb-5'>
        <dl>
          <dt>Organisation</dt>
          <dd>{profile.organisation.name}</dd>
          <dd>{profile.organisation.website}</dd>
        </dl>
      </Page.Section>
    </Page>
  )
}

const mapStateToProps = state => ({
  profile: state.user
})

export default connect(mapStateToProps)(UserProfile)
