import React, { useEffect, useRef } from 'react'
import { Chart, BarController, BarElement, CategoryScale, LinearScale } from "chart.js"
import c from './IncidentBarChart.module.scss'

Chart.register(BarController, BarElement, CategoryScale, LinearScale)

const IncidentBarChart = ({ minor = [], major = [], severe = [], crisis = [] }) => {
  const chartRef = useRef()

  // Get maximum length, should always be even either way
  const count = Math.max(minor.length, major.length, severe.length, crisis.length)

  // Get current and last date
  let currentDate = (new Date()).toDateString()
  let lastDate = new Date()
  lastDate.setDate(lastDate.getDate() - count + 1)
  lastDate = lastDate.toDateString()
  currentDate = currentDate.split(" ").slice(1, 3).join(" ")
  lastDate = lastDate.split(" ").slice(1, 3).join(" ")
 
  const labels = Array.from({ length: count }, (_, i) => i)

  useEffect(() => {
    const ref = chartRef.current.getContext("2d")

    const data = {
      labels: labels,
      datasets: [
        {
          label: "Crisis",
          data: crisis,
          backgroundColor: "#FF4A4A",
        },
        {
          label: "Severe",
          data: severe,
          backgroundColor: "#e37bde",
        },
        {
          label: "Major",
          data: major,
          backgroundColor: "#E0B259",
        },
        {
          label: "Minor",
          data: minor,
          backgroundColor: "#71ADBB",
        },
      ],
    }

    const chart = new Chart(ref, {
      type: "bar",
      data: data,
      options: {
        scales: {
          x: {
            stacked: true,
            display: false,
          },
          y: {
            stacked: true,
            display: false,
          },
        },
      },
    })

    return () => {
      chart.destroy()
    }
  }, [minor, major, severe, crisis]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={c.container}>
      <canvas ref={chartRef} className={c.chart}></canvas>
      <div className={c.columns}>
        <div></div>
        <div></div>
      </div>
      <div className={c.dates}>
        <div>{lastDate}</div>
        <div>{currentDate}</div>
      </div>
    </div>
  )
}

export default IncidentBarChart
