import types from "../types/user"

export const user = (
  state = {
    isWelcomeVisited: false,
    authenticated: false,
    permissions: [],
    role: null,
    id: null,
  },
  { type, payload }
) => {  
  switch (type) {
    case types.welcomePageVisited: {
      return {
        ...state,
        isWelcomeVisited: true,
      }
    }
    case types.setUser: {
      return {
        ...state,
        ...payload,
        authenticated: true,
      }
    }
    case types.setToken: {
      return {
        ...state,
        token: payload,
        authenticated: true,
      }
    }
    case types.removeUser: {
      return {
        ...state,
        username: null,
        email: null,
        token: null,
        authenticated: false,
      }
    }
    default:
      return state
  }
}

export default user
