import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Formik, Field, useField } from "formik"
import Page, { useMenu } from '../components/Page'
import Document from "../components/Document"
import ListItem from "../components/ListItem"
import ErrorPage from 'aius-comp/ErrorPage'
import Loading from 'aius-comp/Loading'
import { LabelToggle } from "aius-comp/Toggle"
import axios from 'axios'
import c from "./HotelDetailsEdit.module.scss"
import Button from 'aius-comp/Button'
import Input from 'aius-comp/Input'
import Dropzone from 'aius-comp/Dropzone'
import { readFile } from 'aius-fn/readFile'
import { capitalise } from 'aius-fn'

/*
  Edit hotel details. 
  Dynamic schema is fetched when page is mounted.
  Static schema is set in 'initialValues', a subset of hotel database table schema
*/

const HotelDetailsEdit = () => {
  const location = useLocation()
  const [openMenu, controls] = useMenu()
  const onBackPath = location.state?.onBackPath
  const { id } = useParams()
  const history = useHistory()
  const [schema, setSchema] = useState()
  const [hotel, setHotel] = useState(location.state?.hotel ?? undefined)
  const [error, setError] = useState(false)
  const [imagePreviewUrl, setImagePreviewUrl] = useState()
  const [schemaLoading, setSchemaLoading] = useState(true)
  const [initialDetails, setInitialDetails] = useState({});
  const [hotelLoading, setHotelLoading] = useState(hotel === undefined)
  const [page, setPage] = useState(-1)

  // Fetch hotel schema
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/locations/schema`)
      .then(res => {
        const schema = res.data?.data
        setSchema(schema)
      })
      .catch(e => {
        console.error(e)
        setError(true)
      })
      .finally(() => {
        setSchemaLoading(false)
      })
  }, [])

  // If not provided, fetch hotel data
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/locations/${id}/full`)
      .then(res => {
        const hotelDetails = res.data?.location
        setHotel(hotelDetails)
        setImagePreviewUrl(hotelDetails.coverImageURL)


        const initialDetails = {}
        hotelDetails.details.forEach(detail => {
          let castedValue = detail.value;
          if(detail.schema.type === "BOOL" || detail.schema.type === "Boolean"){
            castedValue = (castedValue === '1');
          }

          initialDetails[detail.schema.name] = { 
            value: castedValue, 
            property_schema_id: detail.property_schema_id
          }
        })

        setInitialDetails(initialDetails)
      })
      .catch(e => {
        console.error(e)
        setError(true)
      })
      .finally(() => {
        setHotelLoading(false)
      })
  }, [id])

  if (schemaLoading || hotelLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <Loading size='large' />
      </div>
    )
  }

  if (error) {
    console.error("Couldn't retrieve hotel/schema information")
    return <ErrorPage title='Unexpected error' description="Couldn't retrieve information" />
  }

  if (!hotel) {
    console.warn("Couldn't retrieve hotel information")
    return <ErrorPage title='No hotel' description="Hotel information wasn't found" />
  }

  const handleEditSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
      const { file: rawFile, ...rest} = values
      const file = rawFile ? await readFile(rawFile) : undefined
      const location = {
        file: file,
        ...rest,
        details: Object.values(values?.details) || [],
      }
  
      axios
        .patch(`${process.env.REACT_APP_BACKEND_URL}/locations/${id}`, { location })
        .then(() => {
          console.log("[HotelDetailsEdit] Edit submitted successfully")
          history.push(`/hotels/${id}`)
        })
        .catch(e => {
          console.error(e)
          console.error(e?.response?.data?.message)
          setSubmitting(false)
        })
  }

  // Pagination
  const changeStep = (change, min, max) => {
    return () => {
      const nextPage = Math.min(max, Math.max(min, page + change))
      if (page !== nextPage) {
        setPage(nextPage)
      }
    }
  }
  
  // Aggregate details by category
  const groupedSchema = Array.isArray(schema)
    ? schema.reduce((acc, input) => {
        const { category, ...rest } = input

        if (!acc[category]) {
          acc[category] = []
        }
        acc[category].push(rest)

        return acc
      }, {})
    : {}
  
  const displayEnum = value => capitalise(value.split("_").join(" ").toLowerCase(), true)

  /*
    Page indices:
      -1: Static main page
      0-n: Dynamic detail pages
  */
  const minPage = -1
  const maxPage = Object.keys(groupedSchema).length - 1
  const next = changeStep(1, minPage, maxPage)
  const prev = changeStep(-1, minPage, maxPage)
    
  const currentCategory = Object.keys(groupedSchema).sort()?.[page]
  const currentGroup = groupedSchema?.[currentCategory]

  

  // Displays Hotel Icon
  const ImgUpload =({ onChange, src, }) => {
    return(
      <label htmlFor="photo-upload">
        <div className={c.imgwrap} >
          <img className={c.img} htmlFor="photo-upload" src={src} alt="hotel icon"/>
        </div>
      </label>
    );
  }

  const photoUpload = (files) => {
    const reader = new FileReader();
    const file = files[0];
    reader.onloadend = () => {
        setImagePreviewUrl(reader.result)
    }
    reader.readAsDataURL(file);
  }


  return (
    <Page {...controls} active={true}>
      <Page.Top
        back
        onMenuOpen={openMenu}
        onBack={() => (onBackPath ? history.push(onBackPath) : history.push(`/hotels/${id}`))}
      >
        {`Edit ${hotel.name}`}
      </Page.Top>
      <Page.Section>
        <div className='d-flex justify-content-between align-items-center'>
          <Button onClick={prev} text disabled={minPage === page}>
            Previous
          </Button>
          <Button onClick={next} text disabled={maxPage === page}>
            Next
          </Button>
        </div>
      </Page.Section>
      <Page.Hr className='mb-4' />
      <Page.Section>
        <Formik
          enableReinitialize
          initialValues={{
            address: hotel.address,
            city: hotel.city,
            country: hotel.country,
            file: undefined,
            email: hotel.email,
            generalManagerId: hotel.generalManagerId,
            name: hotel.name,
            phoneNumber: hotel.phoneNumber,
            postcode: hotel.postcode,
            referenceNumber: hotel.referenceNumber,
            website: hotel.website,
            details: initialDetails,
          }}
          onSubmit={handleEditSubmit}
        >
          {({ values, errors, handleSubmit, setFieldValue }) => (
            <>
              {page === -1 ? (
                <>
                  <h2>Main Information</h2>
                  <div className={c.fields}>
                    <Field name='city' error={errors.username} label='City' as={Input} />
                    <Field name='country' error={errors.username} label='Country' as={Input} />
                    {/* Choose Hotel Icon */}
                    <ImgUpload name='coverImageURL' src={imagePreviewUrl} />
                    <Dropzone
                      name='coverImageURL'
                      label='Hotel Icon'
                      onChange={files => {
                        photoUpload(files)
                        setFieldValue("file", files?.[0] ?? undefined)
                      }}
                      selected={
                        values.file ? (
                          <ListItem>
                            <Document>{values.file.name}</Document>
                          </ListItem>
                        ) : undefined
                      }
                    />

                    <Field name='email' error={errors.username} label='Email' as={Input} />
                    <Field name='name' error={errors.username} label='Name' as={Input} />
                    <Field name='phoneNumber' error={errors.username} label='Phone Number' as={Input} />
                    <Field name='postcode' error={errors.username} label='Post Code' as={Input} />
                    <Field name='referenceNumber' error={errors.username} label='Reference Number' as={Input} />
                    <Field name='website' error={errors.username} label='Website' as={Input} />
                  </div>
                </>
              ) : page > -1 && page <= maxPage ? (
                <>
                  <h2>{displayEnum(currentCategory)}</h2>
                  <div className={c.fields}>
                    {currentGroup?.map(detail => (
                      <DetailElement key={detail.id} detail={detail} />
                    ))}
                  </div>
                </>
              ) : (
                <h4>Page out of range</h4>
              )}

              <div className='d-flex justify-content-end pb-4'>
                <Button className='mt-4' onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </>
          )}
        </Formik>
      </Page.Section>
    </Page>
  )
}

const DetailElement = ({ detail }) => {
  // eslint-disable-next-line
  const [field, _ , helpers] = useField(`details.${detail.name}`)
  const { type } = detail
  if (['Text'].includes(type)) {
    const val = field.value?.value || '';
    return (
      <Field
        label={detail.label}
        name={field.name}
        value={val}
        onChange={e =>
          helpers.setValue({
            value: e.target.value,
            property_schema_id: detail.id,
          })
        }
        as={Input}
      />
    )
  } else if (['Date'].includes(type)) {
    const val = field.value?.value || '';
    return (
      <Field
        label={detail.label}
        name={field.name}
        value={val}
        type={type} // same as input only added parameter is date
        onChange={e =>
          helpers.setValue({
            value: e.target.value,
            property_schema_id: detail.id,
          })
        }
        as={Input}
      />
    )
  } else if (['Number'].includes(type)) {
    return (
      <Field
        label={detail.label}
        name={field.name}
        value={field.value?.value}
        onChange={e =>
          helpers.setValue({
            value: e.target.value,
            property_schema_id: detail.id,
          })
        }
        type='number'
        step='1'
        as={Input}
      />
    )
  } else if (["Float"].includes(type)) {
    return (
      <Field
        label={detail.label}
        name={field.name}
        value={field.value?.value}
        onChange={e =>
          helpers.setValue({
            value: e.target.value,
            detailSchemaID: detail.id,
          })
        }
        type='number'
        as={Input}
      />
    )
  } else if (["Boolean"].includes(type)) {
    let val = field?.value?.value;
    return (
      <Field
        label={detail.label}
        name={field.name}
        value={val}
        onChange={toggled =>
          helpers.setValue({
            value: toggled,
            property_schema_id: detail.id,
          })
        }
        as={LabelToggle}
      />
    )
  } else {
    return (
      <Field
        label={detail.label}
        name={field.name}
        value={field.value?.value}
        onChange={e =>
          helpers.setValue({
            value: e.target.value,
            property_schema_id: detail.id,
          })
        }
        as={Input}
      />
    )
  } 
}

export default HotelDetailsEdit
