import { useState } from "react";
import Page, { useMenu } from "../components/Page";
import Button from "aius-comp/Button";
import { Input } from "aius-comp/Input";
import InputBox from "../library/InputBox";
import c from './AssessmentQuestion.module.scss'
import { useHistory } from "react-router";

const AssessmentQuestion = (props) => {
    const [openMenu, controls] = useMenu();
    const [options, setOptions] = useState([]);

    const [option, setOption] = useState('');
    const [score, setScore] = useState(0);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const history = useHistory();

    const handleSubmit = e => {
        e.preventDefault();
        e.target.reset();
        setOptions([...options, {option:option, score:score}]);
    }

    const handleOptionChange = e => {
        setOption(e.target.value)
    }

    const handleNumberChange = e => {
        setScore(e.target.value)
    }

    const handleChangeName = e => {
        setName(e.target.value)
    }

    const handleChangeDescription = e => {
        setDescription(e.target.value)
    }

    const saveHandler = e => {
        e.preventDefault();
        console.log( name, description, options )
    }

    return (
        <Page {...controls}>
            <Page.Top onMenuOpen={openMenu}>Assessment Question</Page.Top>
            <Page.Section>
                <div className="d-flex flex-column flex-gap-2 mb-5">
                    <Input name="name" label="name" onChange={handleChangeName}/>
                    <InputBox name="description" label="description" onChange={handleChangeDescription}/>

                    <h4>Options</h4>
                    <div className={c.optionContainer}>
                        <form onSubmit={handleSubmit}>
                            <input name='option' placeholder="option" className={c.option} onChange={handleOptionChange}/>
                            <input name='score' placeholder="score" className={c.score} type='number' onChange={handleNumberChange}/>
                            <button className={c.add} type="submit">Add</button>
                        </form>

                        {options.map((eachOption, index)=>{
                            return <div key={index}>{eachOption.option}:{eachOption.score}</div>
                        })}
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <Button size="small" variant="warning" onClick={() => {history.goBack()}}>
                        Discard
                    </Button>
                    <Button size="small" onClick={saveHandler}>
                        Save
                    </Button>
                </div>
            </Page.Section>
        </Page>
    );
};

export default AssessmentQuestion;
