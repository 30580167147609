import React from 'react'
import cn from 'classnames'
import c from './RiskListItem.module.scss'

const RiskRecommendationItem = ({
  title,
  description,
  severity,
  className,
  ...rest
}) => {

  const severities = {
    "critical": c.crisis,
    "severe": c.severe,
    "moderate": c.major,
    "low": c.minor,
    "none": c.none,
  }

  const barClasses = cn(c.bar, severities[severity])

  return (
    <div className={c.item} {...rest}>
      <div className={barClasses}></div>

      <div className={c.content}>
        <div className={c.title}>{title}</div>
        <div className={c.description} dangerouslySetInnerHTML={{__html: description}}></div>
      </div>
    </div>
  )
}

export default RiskRecommendationItem
