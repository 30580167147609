import React from 'react'
import PropTypes from 'prop-types'
import calendarIcon from '../images/icons/calendar.svg'
import cn from 'classnames'
import c from "./AssessmentStepItem.module.scss"

import passIcon from '../images/icons/pass.svg'
import failIcon from '../images/icons/fail.svg'
import continueIcon from '../images/icons/arrow-right-small.svg'

const AssessmentStepItem = props => {
  const {
    name, date, score, status, ...rest
  } = props

  const statusClass = {
    pass: [c.pass],
    fail: [c.fail],
    incomplete: [c.incomplete],
    pending: [c.pending],
  }

  const statusText = {
    pass: (
      <>
        <span>PASS</span>
        <img src={passIcon} alt=''/>
      </>
    ),
    fail: (
      <>
        <span>FAILED</span>
        <img src={failIcon} alt=''/>
      </>
    ),
    incomplete: (
      <>
        <span>CONTINUE</span>
        <img src={continueIcon} alt=''/>
      </>
    ),
    pending: (
      <>
        <span>START</span>
        <img src={continueIcon} alt=''/>
      </>
    ),
  }

  return (
    <div className={cn([c.item, statusClass[status]])} {...rest}>
      <div className={c.bar}></div>
      <div className={c.content}>
        <div className={c.name}>{name}</div>
        <div className={c.date}>
          { status !== 'pending' && (
            <>
              <img src={calendarIcon} className={c.calendar} alt=''/>
              {date}
            </>
          )}
        </div>
        <div className={c.bottom}>
          <div>{score}</div>
          <div className={c.status}>{statusText[status]}</div>
        </div>
      </div>
    </div>
  )
}

AssessmentStepItem.propTypes = {
  status: PropTypes.oneOf(['pass', 'fail', 'incomplete', 'pending'])
}

export default AssessmentStepItem
