import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import c from "./Input.module.scss";
import cn from "classnames";
import { useField } from "formik"

/**
  `import Input from "aius-comp/Input"`  

  - Default input element wrapper
  - Passes all attributes to input element, except for label and error message
  - Further documentation: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
**/

export const Input = ({ label, error, className, ...props }) => (
  <div className={cn([c.container, className])}>
    <div className={c.label}>
      {label}
      <span className={c.error}>{error}</span>
    </div>
    <input
      {...props}
      type={props.type ? props.type : "text"}
      className={c.input}
    />
  </div>
);

export const FormicTypeInput = ({ label, error, className, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={cn([c.container, className])}>
      <div className={c.label}>
        {label}
        <span className={c.error}>{error}</span>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
      <input
        {...field}
        {...props}
        type={props.type ? props.type : "text"}
        className={c.input}
      />
    </div>
  );
}

/**
  `import { InputAdder } from "aius-comp/Input"` 

  - Type in text, then press enter or press 'add' icon
  - Useful for lists
  - The text is passed to provided callback 'onAdd'
  - Example:
    - `onAdd={(text) => console.log("I just entered: ", text)}`
**/

export const InputAdder = ({ label, onAdd, ...props}) => {
  const input = useRef(null)
  const [value, _setValue] = useState('')

  const setValue = value => {
    try {
      input.current.value = value
      _setValue(value)
    } catch(err) {}
  }

  const handleChange = e => {
    try {
      setValue(e.target.value)
    } catch (err) {}
  }

  const handleAdd = e => {
    if (value && value.trim()) {
      if (e && e.keyCode !== 13) { return }
      onAdd(value)
      setValue('')
    }
  }

  const buttonStyles = cn(
    c.addButton,
    {[c.hideButton]: !(value && value.trim())}
  )

  return (
    <div className={c.container}>
      <div className={c.label}>
        {label}
        <span className={c.error}>{props.error}</span>
      </div>
      <div className={c.inputWrapper}>
        <input
          {...props}
          type="text"
          className={c.input}
          onKeyUp={handleAdd}
          onChange={handleChange}
          ref={input}
        />
        <div className={buttonStyles}>
          <img src={require("./images/icons/Add Circle.svg")} alt="add icon" onClick={() => handleAdd()} />
        </div>
      </div>
    </div>
  )
}

InputAdder.defaultProps = {
  onAdd: (value) => console.log('[InputAdder] default callback, none provided. Value: ', value),
}

InputAdder.propTypes = {
  onAdd: PropTypes.func.isRequired,
}

export default Input;
