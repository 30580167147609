import React from "react"
import { useHistory } from "react-router-dom"
import c from "./Hotel.module.scss"

const Hotel = props => {
  const history = useHistory()
  const {
    id,
    name,
    city,
    coverImageURL,
  } = props
  
  return (
    <div className={c.hotel} onClick={() => history.push(`/hotels/${id}`)}>
      <img className={c.icon} src={coverImageURL} alt=''></img>
      <div className={c.details}>
        <div className={c.name}>{name}</div>
        <div className={c.city}>{city}</div>
      </div>
    </div>
  )
}

export default Hotel