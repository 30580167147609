import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import RenderElement from "./RenderElement"
import ProgressBar from "./ProgressBar"
import Button from "aius-comp/Button"
import cn from "classnames"
import c from "./AssessmentStep.module.scss"

/*
  Step of a workflow, which is split into several pages,
  where each page contains a single element
*/

const AssessmentStep = ({ step, responses, onSubmit, stepIndex, workflow }) => {
  const history = useHistory()
  const [score, setScore] = useState({})
  const { id, name, description, elements, sortOrder } = step
  const [index, setIndex] = useState(-1)

  // Current shown element
  const element = elements[index]
  const response = responses?.find(response => response.elementId === element?.id)

  const changeIndex = change => {
    return () => {
      // Index is allowed to increase beyond the element length (== elements.length),
      // since the last index is used as a completion step
      const newIndex = Math.min(elements.length, Math.max(0, index + change))
      if (index !== newIndex) {
        setIndex(newIndex)
      }
    }
  }

  const handleSubmit = () => {
    onSubmit()
  }

  const addScore = (id, newScore) => {
    setScore({ ...score, [id]: newScore })
  }

  const calculateScore = () => {
    return Object.values(score).reduce((acc, curr) => acc + curr, 0)
  }

  const completionStatus = () => {
    const maxSelect = step.elements.reduce((acc, curr) => curr.type === 'SELECT' ? acc + 1 : acc, 0)
    const currentSelect = Object.values(score).length
    if (maxSelect === currentSelect) {
      const currentScore = calculateScore()
      const passScore = step.passScore
      if (currentScore >= passScore) {
        return 'pass'
      } else {
        return 'fail'
      }
    } else {
      return 'incomplete'
    }
  } 

  const prev = changeIndex(-1)
  const next = changeIndex(1)
  
  const scoreColor = {
    pass: [c.pass],
    fail: [c.fail],
    incomplete: [c.incomplete],
  }

  const isLastStep = index === elements.length - 1

  return (
    <div className={c.step}>
      {index === -1 ? (
        // Beginning Step
        <>
          <div>
            <h3>{`Section ${stepIndex}`}</h3>
            <h1>{name}</h1>
            <p className='mt-2' style={{whiteSpace: "pre-wrap"}}>{description}</p>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <Button onClick={() => history.goBack()} text>
              Later
            </Button>
            <Button onClick={() => setIndex(0)}>Start</Button>
          </div>
        </>
      ) : index >= 0 && index < elements.length ? (
        // Intermediate Steps
        <>
          <div>
            <ProgressBar current={index} max={elements.length - 1} className='mb-5' />
            <RenderElement addScore={addScore} type={element.type} stepId={id} {...element} default={response} description={element.description}/>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <Button onClick={prev} text disabled={index === 0}>Back</Button>
            {/* [TODO] Possibly show prompt asking whether user wishes to finish the section (step) ? */}
            <Button onClick={next}>{isLastStep ? 'Finish' : 'Next'}</Button>
          </div>
        </>
      ) : index === elements.length ? (
        // Completion Step
        <>
          <div>
            <h3>{`Section ${sortOrder}`}</h3>
            <h1>{name}</h1>
          </div>
          <div className={cn(c.score, scoreColor[completionStatus()])}>
            <div>You Scored</div>
            <div className={c.digit}>{`${calculateScore()} / ${step.maxScore}`}</div>
          </div>
          
        
          {workflow?.documents?.length > 0 &&
            <div>
            <h3>For further information...</h3>
                <ul>
                {
                  workflow.documents?.map( (document) => {
                    return (<li key={document.id}><a href={document.signedUrl} target="_blank" without rel="noreferrer">{document.name}</a></li>)
                  })
                  
                }
                </ul>
            </div>
          }

          <div className='d-flex justify-content-center'>
            <Button onClick={handleSubmit}>Finish</Button>
          </div>
        </>
      ) : (
        <span>Unexpected index</span>
      )}
    </div>
  )
}

export default AssessmentStep
