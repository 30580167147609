import { useParams, useHistory } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import Page, { useMenu } from "../components/Page"
import Select from "react-select"
import Button from "aius-comp/Button";
import Input from "../library/Input";


const AssignWorkflow = () => {
    const history = useHistory()
    const { id } = useParams();
    const [openMenu, controls] = useMenu()
    const [assignedUsers] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [uiUsers, setUisers] = useState([]);
    const [deuAt, setDeuAt] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/locations/users`).then( res => {
            setUserOptions(res.data.users.map((user) => {
                return {
                    label : user.firstName + ' ' + user.lastName,
                    value : user.id
                }
            }))  
        })  // eslint-disable-next-line
    }, [])

    useEffect(() => {
        /*
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/workflow-assign-user/${id}`
            )
            .then((res) => {
                setAssignedUsers(res.data.users.map((assigned_user) =>{
                    return {
                        label : assigned_user.first_name + ' ' + assigned_user.last_name,
                        value : assigned_user.id
                    }
                }));
            });
            */
        // eslint-disable-next-line
    }, []);

    const handleOnchange = (event) => {
        setUisers(event)
    } 

    const handleSave = () => {
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/workflow-assign-user/${id}`, {user:uiUsers.map(e => e.value), deuAt:deuAt}).then(res => {
            //console.log(res);
            history.goBack()
        })
    }

    return (
        <Page {...controls}>
            <Page.Top onMenuOpen={openMenu}>Assign</Page.Top>
            <Page.Section className="mt-2">
            <Select 
                isMulti
                label='Assigned Users'
                options={userOptions}
                onChange={handleOnchange}
                value={[...new Set([...assignedUsers, ...uiUsers])]}
                
            />
            <Input
                label="Due At"
                onChange={e => setDeuAt( e.target.value)}
                type='datetime-local'
            />
            <br />
            <br />
            <Button onClick={handleSave}>Done</Button>
            </Page.Section>
        </Page>
    );
};

export default AssignWorkflow;
