import React from "react"
import PropTypes from "prop-types"
import { CSVLink } from "react-csv"
import c from "./Button.module.scss"
import cn from "classnames"

/**
- 
**/

export const Button = ({ square, variant, wide, size, light, text, disabled, children, onClick, className, ...rest }) => {

  const sizes = {
    large: [c.large],
    normal: [c.normal],
    small: [c.small],
    tiny: [c.tiny],
  }

  const variants = {
    grey: [c.grey],
    warning: [c.warning],
  }

  const classes = cn(
    c.button,
    sizes[size],
    className,
    {
      [c.wide]: wide,
      [c.light]: light,
      [c.disabled]: disabled,
      [c.text]: text,
      [c.square]: square,
    },
    variants[variant],
  )

  return (
    <div className={classes} onClick={disabled ? null : onClick} {...rest}>
      <span>{children}</span>
    </div>
  )
}

export const DownloadButton = ({ data, filename, headers, children, ...rest }) => (
  <Button {...rest}>
    <CSVLink data={data} filename={filename} headers={headers} target='_blank' className={c.downloadButton}>
      {children}
    </CSVLink>
  </Button>
)

Button.defaultProps = {
  size: "normal",
  wide: false,
  light: false,
  text: false,
  disabled: false,
  square: false,
}

Button.propTypes = {
  size: PropTypes.oneOf(["large", "normal", "small", "tiny", "square"]),
  wide: PropTypes.bool,
  light: PropTypes.bool,
  text: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Button
