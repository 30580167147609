import Button from 'aius-comp/Button'
import ErrorPage from 'aius-comp/ErrorPage'
import Loading from 'aius-comp/Loading'
import axios from 'axios'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import CloseHeader from '../components/CloseHeader'
import Page from '../components/Page'
import ProgressBar from '../components/ProgressBar'
import ReportStep from '../components/ReportStep'
import { systemActions } from '../store/actions'
import c from './AlertWorkflow.module.scss'

/*  
  Page to submit action taken in response to alert. 
*/

const AlertWorkflow = props => {
  const history = useHistory()
  const notificationID = useLocation().state?.notificationID
  const { id: workflowID } = useParams()
  const [index, setIndex] = useState(undefined)
  const [workflowRun, setWorkflowRun] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(true)
  
  // Get action workflow
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${workflowID}`)
      .then(res => setWorkflowRun(res.data.workflowRun))
      .catch(e => console.error(e))
      .finally(() => setLoading(false))
  }, [workflowID])

  // If workflow loading
  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <Loading size='large' />
      </div>
    )
  }

  // If workflow not found
  if (!workflowRun) {
    console.error("Couldn't retrieve workflow")
    return <ErrorPage title='No workflow' description='Workfload could not be loaded' />
  }
  
  // If notification ID not found
  if (!notificationID) {
    console.error("Couldn't find notification ID")
    return <ErrorPage title='No found' description='Notification ID could not be found' />
  }

  const { workflow } = workflowRun
  const { steps, name } = workflow

  // Sort steps by order
  steps.sort((step1, step2) => step1.sortOrder - step2.sortOrder)

  // Get current step
  let currentStep
  if (index !== undefined) {
    currentStep = steps[index]
  } else {
    setIndex(0)
    currentStep = steps[0]
  }

  const maxSteps = steps.length
  const isLastStep = index === maxSteps - 1

  // Step pagination
  const changeStep = change => {
    return () => {
      const nextIndex = Math.min(maxSteps - 1, Math.max(0, index + change))
      if (index !== nextIndex) {
        setIndex(nextIndex)
      }
    }
  }

  // Complete alert action
  const handleSubmit = async () => {
    try {
      setSubmitting(true)
      const data = { state: "CLOSED" }
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${workflowRun.id}`, data)
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/notifications/${notificationID}/action-run`, { runID: workflowRun.id })
      props.flashMessage("Action submitted ✅")
      props.fetchNotifications()
      history.push("/")
    } catch (e) {
      console.error(e)
      setSubmitting(false)
    }
  }

  const cancelAlertAction = async () => {
    try {
      const data = { state: "CANCELLED" }
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${workflowRun.id}`, data)
      props.flashMessage("Action cancelled ✖")
      history.goBack()
    } catch (e) {
      console.error(e)
      history.goBack()
    }
  }

  const next = changeStep(1)
  const prev = changeStep(-1)

  return (
    <Page className='h-100'>
      <Page.Section>
        {/* Top */}
        <CloseHeader title={name} onClose={cancelAlertAction} />
        <ProgressBar current={index} max={steps.length - 1} className='mb-5' />
      </Page.Section>

      {/* Steps */}
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <>
            <Page.Section>
              {/* Steps */}
              <div className={c.steps}>
                <ReportStep step={currentStep} responses={undefined} />
              </div>
            </Page.Section>

            <Page.Section className={c.bottom}>
              {/* Navigation */}
              <div>
                {isLastStep ? (
                  <Button onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </Button>
                ) : (
                  <Button onClick={next}>Next</Button>
                )}
                {index !== 0 ? (
                  <Button onClick={prev} text>
                    Back
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </Page.Section>
          </>
        )}
      </Formik>
    </Page>
  )
}

const mapDispatchToProps = dispatch => ({
  flashMessage: message => dispatch(systemActions.flashMessage(message)),
  fetchNotifications: () => dispatch(systemActions.fetchNotifications()),
})

export default connect(null, mapDispatchToProps)(AlertWorkflow)


