export const readFile = file => {
  return new Promise((resolve, reject) => {

    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () =>
      resolve({
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        fileBase64: btoa(reader.result),
      });

    reader.onerror = (error) => {
      console.error(error);
      reject(null);
    };

  });
}

export const readFiels = files => {
  // Return multiple promises
}

export default readFile