import React from 'react'
import c from './NotificationIcon.module.scss'

// Default
import defaultIcon from '../images/notification-icons/unknown.png'

// Unknown


// [TODO] ASSESSMENT_ASSIGNMENT
const icons = {
  UNKNOWN: {
    NONE: defaultIcon,
  }
}

const NotificationIcon = ({ icon, ...rest }) => {
  icon = (icon) ? icon : icons["UNKNOWN"]["NONE"]
  return (
    <div {...rest}>
      <img src={icon} className={c.icon} alt=''/>
    </div>
  )
}

export default NotificationIcon