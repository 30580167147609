import React from 'react'
import c from './LocationDetailListItem.module.scss'

const LocationDetailListItem = props => {
  const {
    value,
    label,
    type,
    icon
  } = props

  let text

  if (type === "Boolean") {
    if (!value || (value === '0')) return null
    text = label
  } else {
    text = `${label}: ${value}`
  }

  return (
    <div className={c.detail}>
      <div className={c.icon}>
        {icon ? <i className={icon}></i> : <div className={c.placeholder}></div>}
      </div>
      <div className={c.text}>{text}</div>
    </div>
  )
}

export default LocationDetailListItem
