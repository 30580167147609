import React from 'react'
import bellIcon from '../images/icons/bell.svg'
import cn from 'classnames'
import c from './Bell.module.scss'

/*
  Notification bell component.
  Displays red dot if active.
*/

const Bell = ({ active = false, className, ...rest }) => {
  const containerClasses = cn(c.container, className)  
  return (
    <div {...rest} className={containerClasses}>
      <img className={c.bell} src={bellIcon} alt=''/>
      { active && <div className={c.dot}></div> }
    </div>
  )
}

export default Bell
