import React from 'react'
import IconItem from "./IconItem"
import calendarIcon from '../images/icons/calendar.svg'
import c from './DocumentListItem.module.scss'

const DocumentListItem = props => {
  const {
    user,
    createdAt,
  } = props

  const date = (new Date(createdAt)).toDateString()
  return (
    <div className={c.item} onClick={props.onClick}>
      <div className={c.title}>{props.title}</div>
      <div className={c.details}>
        <IconItem text={`${user?.firstName} ${user?.lastName}`} icon={user?.profileImageURL} />
        <div className={c.date}>
          <img src={calendarIcon} className={c.icon} alt=''/>
          {date}
        </div>
      </div>
    </div>
  )
}

export default DocumentListItem
