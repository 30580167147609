import { useEffect } from "react"
import { withRouter } from "react-router-dom"

/*
  Utility component that scrolls to the top of the page on mount.
  Used when changing pages.
*/

const ScrollToTop = ({ history }) => {
  
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  return <></>
}

export default withRouter(ScrollToTop)
