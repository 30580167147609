import React, { useState } from "react"
import RenderElement from "./RenderElement"
import c from "./ReportStep.module.scss"
import crossIcon from '../library/images/icons/trash-icon.png'
import { connect } from "react-redux";
import { systemActions } from "../store/actions";

import axios from "axios";

const ReportStep = ({ step, responses, ...props }) => {
  const { id, name, description, elements } = step
  const [isFileRemoved, setIsFileRemoved] = useState(false)

  const handleRemoveFromServer = (id) => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/workflow-file/${id}`)
        .then((res) => {
          if(res.data?.status){
            props.flashMessage("File has been deleted ✅");
            setIsFileRemoved(true);
          } else {
            props.flashMessage("Cannot delete the file ❌");
          }
        })
        .catch((err) => console.error(err));
    
  }


  return (
    <div className={c.step}>
      <h2>{name}</h2>
      <p>{description}</p>
      {elements.map(element => {
        const response = responses?.find(response => response.elementId === element?.id)
        return (
          <div key={element.id} >
            <RenderElement type={element.type} stepId={id} {...element} default={response} />
            {response?.element?.type === 'FILE' && !isFileRemoved &&
              <div>
                <h4>Uploaded File</h4>
                <p><a href={response.value} target='_blank' rel="noreferrer">Click Here to View</a>  <img src={crossIcon} className={c.icon} onClick={()=>{handleRemoveFromServer(response.id)}} alt=''/></p>
              </div>
            }
          </div>
        )
      })}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  flashMessage: (message) => dispatch(systemActions.flashMessage(message)),
});

export default connect(null, mapDispatchToProps)(ReportStep);