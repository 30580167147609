import Button from 'aius-comp/Button'
import { capitalise } from 'aius-fn'
import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import LocationDetailListItem from '../components/LocationDetailListItem'
import Page, { useMenu } from '../components/Page'

/*
  Show dynamic details of hotel
*/

const HotelAllDetails = () => {
  const [openMenu, controls] = useMenu()
  const history = useHistory()
  const { id } = useParams()
  const [hotel, setHotel] = useState(useLocation().state?.hotel)

  useEffect(() => {
    //if (!hotel) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/locations/${id}/full`)
        .then(res => setHotel(res.data?.location))
        .catch(e => console.error(e))
    //}
  }, []) //hotel, id

  // Aggregate details by category
  const details = Array.isArray(hotel?.details)
    ? hotel.details.reduce((acc, detail) => {
        const { schema, value } = detail
        const { category, ...rest } = schema

        if (!acc[category]) {
          acc[category] = []
        }
        acc[category].push({ value, ...rest })
        
        return acc
      }, {})
    : {}

  const displayEnum = value => capitalise(value.split("_").join(" ").toLowerCase(), true)

  return (
    <Page {...controls}>
      <Page.Top back onMenuOpen={openMenu} onBack={() => history.push(`/hotels/${id}`)}>
        All Details
      </Page.Top>

      {/* List | All Details */}
      <div className='mt-5'>
        {Object.keys(details).map(category => (
          <div key={category}>
            <Page.Section>
              <div>{displayEnum(category)}</div>
            </Page.Section>
            <Page.Hr />
            <Page.Section>
              {details[category]?.map((detail, index) => (
                <LocationDetailListItem
                  key={index}
                  value={detail.value}
                  label={detail.label}
                  type={detail.type}
                  icon={detail.iconURL}
                  order={detail.order}
                />
              ))}
            </Page.Section>
          </div>
        ))}
      </div>

      {/* Controls */}
      <Page.Section>
        <div className='d-flex justify-content-end pb-4 pt-5'>
          <Button
            onClick={() => history.push(`/hotels/${id}/edit`, { hotel: hotel, onBackPath: `/hotels/${id}/all-details` })}
            variant='grey'
          >
            Edit Hotel
          </Button>
        </div>
      </Page.Section>
    </Page>
  )
}

export default HotelAllDetails
