import Page, { useMenu } from "../components/Page"
import axios from "axios"
import { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router"
import AsyncSelect from "react-select/async"
import Button from "aius-comp/Button"
import { connect } from 'react-redux'
import { systemActions } from '../store/actions'
import { RiBarChartGroupedFill, RiHotelLine } from "react-icons/ri";

const AssessmentAssignments = (props) => {
    const { id } = useParams();
    const [openMenu, controls] = useMenu();
    const history = useHistory();

    const [defaultOptions, setDefaultOptions] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/locations/assignable/${id}`
            )
            .then((result) => {
                setDefaultOptions(mapOptionsToValues(result.data.allOptions));
                setSelectedValues(result.data.selectedValues);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const groupStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    };
    const groupBadgeStyles = {
        backgroundColor: "#EBECF0",
        borderRadius: "2em",
        color: "#172B4D",
        display: "inline-block",
        fontSize: 12,
        fontWeight: "normal",
        lineHeight: "1",
        minWidth: 1,
        padding: "0.16666666666667em 0.5em",
        textAlign: "center",
    };

    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const mapOptionsToValues = (groupOptions) => {
        return groupOptions.map((groupOption) => ({
            label: groupOption.label,
            options: groupOption.options.map((option) => ({
                value: groupOption.identifier+'-'+option.id,
                label: <>{groupOption.identifier.includes('propertyGroup') ? <RiBarChartGroupedFill /> : <RiHotelLine />} {option.name}</>,
            })),
        }));
    };

    /*
    const getOptions = (inputValue, callback) => {
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/locations/assignable/${id}`
            )
            .then((result) => {
                callback(mapOptionsToValues(result.data));
            });
    };
    */

    const handleChange = e => {
        setSelectedValues(e)
        axios.patch( `${process.env.REACT_APP_BACKEND_URL}/locations/save-assignable/${id}`, e)
            .then(result => {
                if(result.data === 1){
                    const displayMsg = selectedValues.length > 0 ? 'Assigned' : 'Unassigned'
                    props.flashMessage(displayMsg+" Successfully ✅")
                }
            })
    }

    return (
        <>
            <Page {...controls}>
                <Page.Top onMenuOpen={openMenu}>Assign Assessment To Hotels or Hotel Groups</Page.Top>
                <Page.Section>
                    <div className="d-flex flex-column flex-gap-2 mb-5 mt-5">
                    <AsyncSelect
                        isMulti
                        cacheOptions
                        closeMenuOnSelect={false}
                        //loadOptions={getOptions}
                        defaultOptions={defaultOptions}
                        value={selectedValues}
                        formatGroupLabel={formatGroupLabel}
                        noOptionsMessage={() => <span>Nothing found</span>}
                        onChange={handleChange}
                    />
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <Button
                            size="large"
                            variant="grey"
                            onClick={() => {history.goBack()}}
                        >
                            Back
                        </Button>
                    </div>
                </Page.Section>
            </Page>
        </>
    );
};
const mapDispatchToProps = dispatch => ({
    flashMessage: message => dispatch(systemActions.flashMessage(message))
})
export default connect(null, mapDispatchToProps)(AssessmentAssignments);
