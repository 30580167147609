import React from "react"
import c from "./WorkflowExitPopup.module.scss"

const WorkflowExitPopup = props => {
  return (
    <div className={c.overlay} onClick={props.onClose}>
      <div className={c.modal} onClick={e => e.stopPropagation()}>
        <div className={c.text}>
          <h1>Incomplete Report</h1>
          <p>Would you like to return to this later or discard it ?</p>
        </div>
        {props.children}
      </div>
    </div>
  )
}

export default WorkflowExitPopup
