import React from 'react'
import Button from 'aius-comp/Button'
import cn from 'classnames'
import c from './Dialog.module.scss'

const Dialog = ({ title, children, onSuccess, onCancel, onOverlay, visible }) => {
  const overlayClasses = cn({ [c.overlay_visible]: visible }, c.overlay)
  const dialogClasses = cn(c.dialog, { [c.dialog_visible]: visible })
  return (
    <div className={overlayClasses} onClick={onOverlay || onCancel}>
      <div className={dialogClasses} onClick={e => e.stopPropagation()}>
        <div className={c.content}>
          <div className='display-4 mb-4'>{title}</div>
          <div>{children}</div>
        </div>
        <div className={c.controls}>
          <Button onClick={onCancel} variant='grey' size='small'>
            Cancel
          </Button>
          <Button onClick={onSuccess} size='normal'>
            Yes
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Dialog
