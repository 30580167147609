import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
//import { connect } from "react-redux"
//import { workflowActions } from "../store/actions"
import Button from "aius-comp/Button"
import FileInfo from "aius-comp/FileInfo"
import CloseHeader from "../components/CloseHeader"
import sanitizeHtml from "sanitize-html"
import c from "./ReportBegin.module.scss"
import Page from "../components/Page"
import axios from "axios"
import Loading from "aius-comp/Loading"

/*  
  Start incident/intelligence report run.
  Displays description and documents of the report type.
*/

const ReportBegin = ({ workflows, fetched, ...props }) => {
  const history = useHistory()

  // Get workflow ID as URL parameter
  const { id: workflowID } = useParams()

  // Get workflow from state by ID
  //const workflow = workflows?.filter(({ id: filterId }) => parseInt(filterId) === parseInt(workflowID))?.[0]
  const [workflow, setWorkflow] = useState()
  const [loading, setLoading] = useState(true)

  // Call fetch workflows action creator, if workflows haven't been fetched yet
  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${workflowID}/full`)
        .then(res => setWorkflow(res.data?.workflowRun))
        .catch(err => console.error(err)).finally(() => setLoading(false))
  }, [workflowID]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <Loading size='large' />
      </div>
    )
  }

  if (!loading && !workflow) {
    return <h2>Workflow not found</h2>
  }

  const { name, documents, description: dangerousDescriptionHTML } = workflow.workflow

  // Sanitise description HTML
  const sanitizedHTML = sanitizeHtml(dangerousDescriptionHTML)
  const description = <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>

  return (
    <Page>
      <Page.Section>
        <div >
          <CloseHeader title={name} onClose={() => history.goBack()} />
          <div className={c.content}>
            <div className={c.description}>{description}</div>
            {Array.isArray(documents) &&
              documents.map(doc => (
                <FileInfo
                  key={doc.url}
                  className='mt-2'
                  fileName={doc?.title}
                  fileSize={doc?.size}
                  mimeType={doc?.mimeType}
                  url={doc?.url}
                  info={doc?.info}
                />
              ))}
          </div>

          {/* Bottom */}
          <div className={c.bottom}>
            <Button onClick={() => history.push({
              pathname: `/report/${workflowID}/run`,
              state: { run: workflow}
            })}>Start</Button>
          </div>
        </div>
      </Page.Section>
    </Page>
  )
}

/*
const mapStateToProps = state => ({
  workflows: state.workflows.incidents,
  fetched: state.workflows.fetched,
})

const mapDispatchToProps = dispatch => ({
  fetchWorkflows: () => dispatch(workflowActions.fetchWorkflows()),
})
*/

export default ReportBegin //connect(mapStateToProps, mapDispatchToProps)()
