import React from 'react'
import closeIcon from '../images/icons/cancel.svg'
import cn from 'classnames'
import c from './RightMenu.module.scss'

const RightMenu = ({ isOpen, close, children }) => {
  const menuClasses = cn(
    c.menu,
    {
      [c.open]: isOpen
    }
  )
  return (
    <div className={menuClasses}>
      <div className="d-flex justify-content-end pr-4 pb-5 pt-2">
        <img src={closeIcon} className={c.close} onClick={close} alt='Right menu'/>
      </div>
      <div>
        { children }
      </div>
    </div>
  )
}


const Section = ({ children }) => (
  <div className={c.section}>
    {children}
  </div>
)

RightMenu.Section = Section

export default RightMenu
