import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Page, { useMenu } from '../components/Page'
import TrainingListItem from "../components/TrainingListItem"
import ControlSelect from "../components/ControlSelect"
import c from './Assessments.module.scss'
import axios from 'axios'
import { useHistory } from 'react-router'
import { systemActions } from '../store/actions'
import Button from "aius-comp/Button"


/*
  Show assessments and progress of each hotel
*/
const Trainings = ({ flashMessage, permissions }) => {
  const history = useHistory()
  const [openMenu, controls] = useMenu()
  const [trainings, setTrainings] = useState([])
  const [status, setStatus] = useState('')
  
  useEffect(() => {
    const data = {
      params: {
        status: status,
      }
    }
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/trainings/full`, data)
      .then(res => {
        if(permissions.includes('hotels:aggregate')){
          const agreecatedTraining = Object.keys(res.data).map(key => {
              if(res.data[key][0]){
                const agreecatedData = {
                  totalAssigned : res.data[key].length,
                  stateCount : {
                    'passed' : res.data[key].filter(e => e['state'] === 'passed').length,
                    'failed' : res.data[key].filter(e => e['state'] === 'failed').length,
                    'incomplete' : res.data[key].filter(e => e['state'] === 'incomplete').length,
                    'not_started' : res.data[key].filter(e => e['state'] === 'not started').length,
                    'INPROGRESS' : res.data[key].filter(e => e['state'] === 'INPROGRESS').length,
                    'CLOSED' : res.data[key].filter(e => e['state'] === 'CLOSED').length,
                    'CANCELLED' : res.data[key].filter(e => e['state'] === 'CANCELLED').length
                  }
                }
                console.log(agreecatedData)
                return { ...res.data[key][0], ...agreecatedData}
              } else {
                return null
              }
              
          }).filter(function (el) {
            return el != null;
          });

          setTrainings(agreecatedTraining)
        } else {
          setTrainings(res.data.trainings)
        }
      })
      .catch(e => {
        console.error(e)
      })
  }, [status, permissions])

  return (
    <Page {...controls}>
      <Page.Top onMenuOpen={openMenu}>Training</Page.Top>
      <div className={c.controls}>
        <ControlSelect onChange={e => setStatus(e.target.value)} defaultValue=''>
          <option value=''>All</option>
          <option value='passed'>Passed</option>
          <option value='incomplete'>Incomplete</option>
          <option value='failed'>Failed</option>
          <option value='not started'>Not Started</option>
        </ControlSelect>
        <Button size='small' variant='info' onClick={() => history.push("/assign-trainings")}>
          Assign
        </Button>
        <Button size='small' variant='info' onClick={() => history.push("/new-training")}>
          New
        </Button>
      </div>
      <div>
        {trainings.map(training => (
          <TrainingListItem
            key={training.runID}
            name={training.name}
            status={training.state}
            hotelImage={training.location?.coverImageURL}
            hotelName={training.location?.name}
            currentProgress={training.completedSteps}
            maxProgress={training.totalSteps}
            currentScore={training.currentScore}
            maxScore={training.maxScore}
            totalAssigned={training.totalAssigned}
            stateCount={training.stateCount}
            onClick={() => {
              if (training.started) {
                history.push(`trainings/${training.runID}/progress`, { onBackPath: "/training" })
              } else {
                flashMessage("Training hasn't been started")
              }
            }}
          />
        ))}
      </div>
    </Page>
  )
}

const mapStateToProps = state => ({
  permissions: state.user.permissions,
})

const mapDispatchToProps = dispatch => ({
  flashMessage: (message) => dispatch(systemActions.flashMessage(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(Trainings)
