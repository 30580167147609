import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import CloseHeader from '../components/CloseHeader'
import ListItem from "../components/ListItem"
import Page from "../components/Page"
import Expandable from "../components/Expandable"
import Search from "aius-comp/Search"
import c from "./Actions.module.scss"

const AssignTasks = props => {
  const { tasks, history } = props
  const [isExpanded, setIsExpanded] = useState(false);
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState(tasks);
  const { hero, ...regular } = tasks.reduce((groups, task) => {    
    if (task.hero) {
      if (!groups['hero']) { groups['hero'] = [] }
      groups['hero'].push(task)
    } else {
      task.categories.forEach(({ category, type }) => {
        if(type === 'Task'){
          if (!groups[category]) { groups[category] = [] }
          groups[category].push(task)
        }
      })
    }
    return groups
  }, {})

  
  const handleSearchKeyUp = e => {
    const searchValue = e.target.value
    if( searchValue.length > 0 ){
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
    setFilter(searchValue);
  }


  useEffect(() => {
    const lowercasedFilter = filter.toLowerCase();
    const filteredData = tasks.filter(item => {
      return item.name.toLowerCase().includes(lowercasedFilter)
    });
    setFilteredData(filteredData)
  }, [filter, tasks])


  return (
    <Page dark>
      <Page.Section>
        {/* Close header */}
        <CloseHeader title='Select Task' onClose={() => history.push("/")} />

        {/* List | Common tasks */}
        <div className={c.workflows}>
          {hero && hero.map(task => (
            <div className={c.workflow} key={task.id} onClick={() => history.push(`/assign-task/${task.id}`)}>
              {task.name}
            </div>
          ))}
        </div>

        {/* Search Workflows */}
        <Search onChange={handleSearchKeyUp} />
      </Page.Section>

      {/*  List | All tasks */}
      <div className='mt-4'>
        
        
        {isExpanded && filteredData.map(task => (
          <div key={task.id}>
            <Page.Hr />
            <ListItem key={task.id} onClick={() => history.push(`/assign-task/${task.id}`)}>
              {task.name}
            </ListItem>
          </div>
        ))}

        { isExpanded && filteredData.length === 0 ? (<ListItem><span className={c.warning}>No item found, Please search with another keyword or expand categories below</span></ListItem>) : ''}

        {( (isExpanded && filteredData.length === 0) || !isExpanded ) && Object.entries(regular).map(([category, tasks]) => (
          <Expandable text={category} key={category}>
            {tasks.map(task => (
              <ListItem key={task.id} onClick={() => history.push(`/assign-task/${task.id}`)}>
                {task.name}
              </ListItem>
            ))}
          </Expandable>
        ))
        }
        
      </div>
    </Page>
  )
}

const mapStateToProps = state => {
  return {
    tasks: state.workflows.tasks,
  }
}

export default connect(mapStateToProps)(AssignTasks)
