import React, { useEffect, useRef } from "react"
import { Chart, PolarAreaController, RadialLinearScale} from "chart.js"
import c from "./HotelProfileChart.module.scss"

/*
  Pie chart used for hotel profile
*/

Chart.register(PolarAreaController, RadialLinearScale)

const HotelProfilePieChart = ({ catastrophe, political, profile, social, colors }) => {

  const chartRef = useRef()

  useEffect(() => {
    const ref = chartRef.current.getContext("2d")

    const severityColors = ["#D2D2D2", "#9DEB9C", "#2E7CF6", "#FCDC00", "#FE9200", "#FC3F3F"]

    let backgroundColor = [
      severityColors[catastrophe - 1],
      severityColors[political - 1],
      severityColors[profile - 1],
      severityColors[social - 1],
    ]

    const data = {
      labels: ["Catastrophe", "Political", "Profile", "Social"],
      datasets: [{
        backgroundColor: colors ? colors : backgroundColor,
        data: [catastrophe, political, profile, social],
      }]
    };

    const image = new Image();
    image.src = '/img/risk-profile-bg.png';

    const plugin = {
      id: 'custom_canvas_background_image',
      beforeDraw: (chart) => {
        if (image.complete) {
          const ctx = chart.ctx;
          const {top, left, width, height} = chart.chartArea;
          const x = left + width / 2 - image.width / 2;
          const y = top + height / 2 - image.height / 2;
          ctx.drawImage(image, x, y);
        } else {
          image.onload = () => chart.draw();
        }
      }
    };

    const chart = new Chart(ref, {
      type: "polarArea",
      data,
      plugins: [plugin],
      options: {
        scales: {
          r: {
            ticks: {
              z: 2,
              min: 0,
              max: 4,
              stepSize: 1,
              display: true,
              font: {
                    size: 10,
              },
              callback: function(value, index, values) {
                if (value === 1) return 'Low'
                if (value === 2) return 'Moderate'
                if (value === 3) return 'High'
                if (value === 4) return 'Severe'
                if (value === 5) return 'Critical'
                return value
              }
            },
          }
        }
      }
    })

    return () => chart.destroy()
  }, [chartRef, catastrophe, political, profile, social, colors]) // eslint-disable-line react-hooks/exhaustive-deps

  return (

    <div className={c.chart}>
      <canvas ref={chartRef}>
      </canvas>
    </div>
  )
}

export default HotelProfilePieChart
