import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import axios from 'axios'
import App from "./App.js"
import { store, persistor } from './store'
import "./index.scss"
import "./fonts/typeface.css"
import '@fortawesome/fontawesome-free/css/all.min.css';

axios.interceptors.request.use(
  config => {
    const token = store.getState().user?.token
    if (!token) console.warn('[Interceptor] Missing JTW token')
    config.headers.Authorization = token ? `Bearer ${token}` : ""
    return config
  }
)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
)
