import React from 'react'
import HotelProfilePieChart from './HotelProfilePieChart'
import c from './IncidentBreakdown.module.scss'

/*
  Used in conjuction with HotelProfilePieChart to display incident statistics
*/

const HotelProfileScoreBreakdown = props => {
  const { catastrophe, political, profile, social , colors } = props

  return (
    <div className={c.breakdown}>
      <HotelProfilePieChart catastrophe={catastrophe} political={political} profile={profile} social={social} colors={colors} />
    </div>
  )
}

export default HotelProfileScoreBreakdown
