import axios from "axios";
import { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import FormicTypeInput from "aius-comp/Input"
import Select from "aius-comp/Select"
import Button from "aius-comp/Button"
import Page from "../components/Page";
import CloseHeader from "../components/CloseHeader"
import { useHistory } from "react-router-dom"
import { LabelToggle } from "aius-comp/Toggle"
import * as Yup from 'yup'

const ProfileMessage = (props) => {
    const history = useHistory()
    const [userOptions, setUserOptions] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/locations/users`).then( res => {
            setUserOptions(res.data.users.map((user) => {
                return {
                    label : user.firstName + ' ' + user.lastName,
                    value : user.id
                }
            }))
        })
    }, []);

    return (
        <Page>
            <Page.Section>
                <CloseHeader title={'Send Message'} onClose={() => history.goBack()} />
                <Formik
                    initialValues={{ title: "", to: "", severity: "", text: "", is_alarm: false }}
                    validationSchema={Yup.object({
                        title: Yup.string().required(),
                        to: Yup.string().required(),
                        severity: Yup.string().required(),
                        text: Yup.string().required()
                    })}
                    onSubmit={async (data) => {
                        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/profile-message`, data);
                        history.goBack()
                    }}
                >
                    {({ handleSubmit, setFieldValue, isValid }) => (
                        <Form>
                            <div className='d-flex flex-column flex-gap-2 mb-5'>
                                <Field 
                                    label="Title"
                                    component={FormicTypeInput}
                                    onChange={e => setFieldValue("title",e.target.value)}
                                />
                                <Field
                                    label='Select User'
                                    isSearchable={false}
                                    options={userOptions}
                                    component={Select}
                                    onChange={e => setFieldValue("to",e.value)}
                                />
                                <Field
                                    label='Severity'
                                    isSearchable={false}
                                    options={[
                                        { value: "MINOR", label: "Minor" },
                                        { value: "MAJOR", label: "Major" },
                                        { value: "SEVERE", label: "Severe" },
                                        { value: "CRISIS", label: "Crisis" },
                                    ]}
                                    component={Select}
                                    onChange={e => setFieldValue("severity", e.value)}
                                />
                                <Field 
                                    label="Text"
                                    component={FormicTypeInput}
                                    onChange={e => setFieldValue("text",e.target.value)}
                                />
                                <Field 
                                    label="Ring the Bell?"
                                    component={LabelToggle}
                                    onChange={e => setFieldValue("is_alarm",e)}
                                />
                            </div>
                            <div className='d-flex justify-content-between align-items-center mt-2'>
                                <Button size="small" variant='warning' onClick={() => history.goBack()}>
                                    Discard
                                </Button>
                                <Button size="normal" disabled={!isValid} onClick={handleSubmit}>
                                    Send
                                </Button>
                            </div>
                        </Form>
                    )}
                    
                </Formik>
            </Page.Section>
        </Page>
    );
};

export default ProfileMessage;
